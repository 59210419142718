import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TodayUpdateCardComponent } from './today-update-card/today-update-card.component';
import { WeeklyUpdateCardComponent } from './weekly-update-card/weekly-update-card.component';



@NgModule({
  declarations: [
    TodayUpdateCardComponent,
    WeeklyUpdateCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    TodayUpdateCardComponent,
    WeeklyUpdateCardComponent
    ]
})
export class DashboardModule { }
