import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-progress-pie-chart',
  templateUrl: './progress-pie-chart.component.html',
  styleUrls: ['./progress-pie-chart.component.css']
})
export class ProgressPieChartComponent implements OnInit {

  @Input('reportsLastDay') reportsLastDay: number;

  constructor() { }

  ngOnInit(): void {
  }

}
