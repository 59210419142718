<div class="container mb-5">
    <div class="row">
      <div class="col-lg-10">
        <div class="card">
          <div class="card-body">
            <div class="sub-title-text font-weight-bold">{{status.name}}</div>
            <p>
              <button *ngIf="status.statusLevel == 1" type="button" class="btn btn-success flag"
                disabled>Clear</button>
              <button *ngIf="status.statusLevel == 2" type="button" class="btn btn-warning flag"
                disabled>Pending</button>
              <button *ngIf="status.statusLevel == 3" type="button" class="btn btn-danger flag"
                disabled>Confirmed</button>
              <span class="pl-4">Status: {{status.statusTitle}}</span>
            </p>
            <p class="pl-4">Date: {{status.symptomReport._symptomReportDateString}}</p>
            <p class="pl-4">Contact With Case: {{status.symptomReport._contactString}}</p>
            <p class="pl-4">Travelled: {{status.symptomReport._travelledString}}</p>
            <p class="pl-4">Symtoms: {{status.symptomReport._symptomsString}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>