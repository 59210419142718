import { Component,  Input,   OnInit } from '@angular/core';
import { SymptomSummary } from 'src/app/_shared/_models/symptom-summary';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
 

@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.css']
})

export class UpdateCardComponent implements OnInit {

  @Input('symptoms') symptoms: SymptomSummary;
  @Input('profile') profile: UserProfile;
   
  

  public reportsLastDay: number;
  public reportsLastWeek: number;
  public isLoading: boolean = false;
 
  public symRepTodayToolTip : string;
  public aboutThisPageToolTip: string;

  constructor() {
    this.symRepTodayToolTip = "This card shows the total number of employees who have completed their symptom reports today.\n\n Remember, it is important to remind your team that even if they do not have a shift that day, they must complete their report every day.";
    this.aboutThisPageToolTip = "What does this page show?\n\n The overview page displays a holistic view of the entire health status of your workforce. This page groups your employees into four categories based on their current status and recent information provided on the @Work employee app.\n\nAt the top of the page, you will see a quick overview of the percentage of your workforce that has completed their mandatory daily symptom reports. To view specific employees you manage, scroll down to see their current status.\n\nHow can i use this information?\n\nAs a manager or employer, you can use this information to ensure all employees entering the workplace have checked in and are reportedly healthy and make decisions that maximize the safety of your department.";
 
 
  }

  ngOnInit(): void {

   
    this.reportsLastDay = (this.symptoms.reportsLastDay*100)/this.symptoms.totalUsers;
    this.reportsLastWeek = (this.symptoms.averageReportsLastWeek*100)/this.symptoms.totalUsers;
 
     

  }

}
