export class Register {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    departmentId: number;
    roleName: string;
    //password: string;
    // acceptNewsEmail: boolean;
    // homeCountry: string;
    // homeTimeZone: string;

    constructor(_email: string, _phoneNumber: string, _firstName: string, 
                _lastName: string, _departmentId: number, _roleName: string) {

        this.email = _email;
        this.phoneNumber = _phoneNumber;
        this.firstName = _firstName;
        this.lastName = _lastName;
        this.departmentId = _departmentId;
        this.roleName = _roleName;
    }
}