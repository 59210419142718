import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
  
import { UpdateCardComponent } from './update-card/update-card.component';
import { ProgressModule } from '../charts/progress.module';
import { CaseStatusCardComponent } from './case-status-card/case-status-card.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
 

@NgModule({
  declarations: [
     
    UpdateCardComponent,
    CaseStatusCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ProgressModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    MatCheckboxModule
  ],
  exports: [
    
    UpdateCardComponent,
    CaseStatusCardComponent,
    ProgressModule
     
  ]
})
export class AtWorkModule { }
