import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AddSymptomReportFormComponent } from './forms/add-symptom-report-form/add-symptom-report-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AddEmployeeSymptomReportComponent } from './forms/add-employee-symptom-report/add-employee-symptom-report.component';

@NgModule({
  declarations: [
    AddSymptomReportFormComponent,
    AddEmployeeSymptomReportComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule
  ],
  exports: [
    AddSymptomReportFormComponent
  ]
})
export class SymptomReportsModule { }
