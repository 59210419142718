import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { StatusReport } from 'src/app/_shared/_models/status-report';
import { StatusReportDetail } from 'src/app/_shared/_models/status-report-detail';

@Component({
  selector: 'app-employee-status-details-card',
  templateUrl: './employee-status-details-card.component.html',
  styleUrls: ['./employee-status-details-card.component.css']
})
export class EmployeeStatusDetailsCardComponent implements OnInit {

  @Input('statusReport') statusReport: StatusReport;

  constructor(private _router: Router) {
  }

  ngOnInit(): void {
  }
  
  goToSymptomReport(status: StatusReportDetail): void {
    status.name = this.statusReport.name;
    status.symptomReport._symptomReportDateString = moment(status.symptomReport.symptomReportDate).format("YYYY/MM/DD");
    status.symptomReport._travelledString = status.symptomReport.travelled ? 'YES' : 'NO';
    status.symptomReport._contactString = status.symptomReport.contactWithCase ? 'YES' : 'NO';

    if (status.symptomReport.symptoms.length > 0) {
      status.symptomReport._symptomsString = "";

      status.symptomReport.symptoms.forEach((symptom, index) => {
        if (index == status.symptomReport.symptoms.length - 1) {
          status.symptomReport._symptomsString += symptom.description;
        } else {
          status.symptomReport._symptomsString += symptom.description + ", ";
        }
      })

    }
    else {
      status.symptomReport._symptomsString = "NONE";
    }

    this._router.navigate(['at-work/symptom-report-details'], {state: status});
  }
}
