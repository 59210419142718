import { Component, OnInit } from '@angular/core';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
import { AccountService } from 'src/app/_shared/_services/account.service';

declare var $: any;

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.css']
})
export class BaseLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
