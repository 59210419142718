import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Symptom, SymptomReport } from 'src/app/_shared/_models/symptom';
import { AlertService } from 'src/app/_shared/_services/alert.service';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';

@Component({
  selector: 'app-add-symptom-report-form',
  templateUrl: './add-symptom-report-form.component.html',
  styleUrls: ['./add-symptom-report-form.component.css']
})
export class AddSymptomReportFormComponent implements OnInit {

  public symptoms: Symptom[];
  public isSubmitted = false;

  public symptomReport: SymptomReport = new SymptomReport();

  public form: FormGroup = new FormGroup({
    travelled: new FormControl(false),
    contact: new FormControl(false)
  });

  constructor(private _atWorkService: AtWorkService,
              private _alertService: AlertService) {

  }

  ngOnInit(): void {
    this._getData();
  }

  private _getData(): void {
    this._atWorkService.getSymptomList().subscribe(res => {
      this.symptoms = res;
      this.symptoms.forEach(symptom => symptom._checked = false);
    },
    error => console.error(error));
  }

  public onSymptomSelect(symptom: Symptom) {
    symptom._checked = !symptom._checked;
  }

  public onSubmit() {
    this.symptomReport.symptoms = this.symptoms.filter(symptom => symptom._checked);
    this.symptomReport.travelled = this.form.get('travelled').value;
    this.symptomReport.contactWithCase = this.form.get('contact').value;

    this._atWorkService.addSymptomReport(this.symptomReport).subscribe(res => { 
      this.isSubmitted = true;
      this._alertService.success("Symptom Report added!");

      

    }, error => {
      console.error(error);
      this._alertService.error(error);
    })
  }

}
