<div class="container-fluid p-0 bg-light h-100 ">
  
  <div class="row h-100 align-items-center justify-content-center">
    
    <div class="col-xl-3 col-lg-3 col-md-4 col-10 text-center p-3 login-wrapper">
      <div class="text-center">
        <img src="https://achuhealth.com/images/achu@work.png" width="150" class="mt-5" alt="">
        <p>By Achu Health™</p>
      </div>
      <app-alert></app-alert>
      <form class="p-4 text-center" [formGroup]="resetForm">
        <p>Please check your spam folder for a password reset email.</p>
        <div class="form-group">
          <label class="d-flex">Email</label>
          <input type="email" formControlName="email" class="form-control valid"
                  [ngClass]="{'is-invalid': submitted && f.email.errors}">
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Valid Email is required</div>
          </div>
        </div>
        <button (click)="onSubmit()" type="submit" class="btn btn-primary custom-primary-btn px-5 mt-3">Submit</button>
      </form>
    </div>
  </div>
</div>
