import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeLink'
})
export class SafeLinkPipe implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) { }

  transform(url: string): SafeResourceUrl {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
