import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Department } from 'src/app/_shared/_models/department';
import { Status } from 'src/app/_shared/_models/status';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
import { Role } from 'src/app/_shared/_models/role';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';
import { ForgotPassword } from 'src/app/_shared/_models/forgot-password';
import { AccountService } from 'src/app/_shared/_services/account.service';
import { AlertService } from 'src/app/_shared/_services/alert.service';

@Component({
  selector: 'app-edit-employee-form',
  templateUrl: './edit-employee-form.component.html',
  styleUrls: ['./edit-employee-form.component.css']
})
export class EditEmployeeFormComponent implements OnInit {

  @Input('departments') departments: Department[];
  @Input('statuses') statuses: Status[];
  @Input('userProfile') userProfile: UserProfile;
  @Input('currentProfile') currentProfile: UserProfile;
  @Input('roles') roles: Role[];

  public isLoading: boolean = false;
  public submitted: boolean = false;
  public notAllowed: boolean = false;

  public profileForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    department: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    role: new FormControl('', [Validators.required]),
    enabled: new FormControl(true)
  });

  constructor(private _atWorkService: AtWorkService,
              private _accountService: AccountService,
              private _alertService: AlertService,
              private _router: Router) {

  }

  ngOnInit(): void {
    this.notAllowed = this.userProfile.role.permissionLevel < this.currentProfile.role.permissionLevel ? true : false;

    this.profileForm.patchValue(this.userProfile);
    this.f.department.patchValue(this.userProfile.department.departmentId);
    this.f.status.patchValue(this.userProfile.status.statusId);
    this.f.email.disable();
    this.f.role.patchValue(this.userProfile.role.id);
  }

  public get f() {
    return this.profileForm.controls;
  }

  public onSubmit(): void {
    this.submitted = true;

    // return if not valid
    if (this.profileForm.invalid) {
      return;
    }

    let selectedRole: Role = this.notAllowed ? this.userProfile.role : this.roles.find(role => role.id == this.f.role.value)

    let profileValue: UserProfile = new UserProfile(
      this.f.firstName.value,
      this.f.lastName.value,
      this.f.email.value,
      this.f.phoneNumber.value,
      this.departments.find(dep => dep.departmentId == this.f.department.value),
      this.statuses.find(status => status.statusId == this.f.status.value),
      selectedRole,
      this.f.enabled.value
    );

    this._atWorkService.updateUserProfile(profileValue).subscribe(res => {
      this.goBack();
    },
    error => {
      console.error(error);
      // alert service here
      this.submitted = false;
    })
  }

  public goBack(): void {
    this._router.navigate(['/employee-management']);
  }

  public resendWelcome() {
    let value: ForgotPassword = new ForgotPassword();
    value.email = this.userProfile.email;

    this._accountService.resendWelcome(value).subscribe(res => {
      this._alertService.success("Invite was successfully re-sent!");
    },
    error => {
      console.error(error);
      this._alertService.error(error.error);
    })
  }

}
