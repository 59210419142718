import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../_shared/_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router,
              private _authenticationSerivce: AuthenticationService) { 

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this._authenticationSerivce.isAuthenticated()) {
      return true;
    }

    // redirect to login
    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

    return false;
  }
  
}
