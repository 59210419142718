import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProgressPieChartComponent } from './progress-pie-chart/progress-pie-chart.component';
import { ProgressPieChartTwoComponent } from './progress-pie-chart-two/progress-pie-chart-two.component';
import { NgCircleProgressModule } from 'ng-circle-progress';




@NgModule({
  declarations: [
    ProgressPieChartComponent,
    ProgressPieChartTwoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgCircleProgressModule.forRoot({
      radius: 80,
      outerStrokeWidth: 13,
      innerStrokeWidth: 13,
      space: -13,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      titleFontSize: '50',
      titleColor: '#FFFFFF',
      unitsFontSize: '50',
      unitsColor: '#FFFFFF',
      maxPercent: 100,
      subtitle: '',
      subtitleFontSize: '0'
    })
  ],
  exports: [
    ProgressPieChartComponent,
    ProgressPieChartTwoComponent
  ]
})
export class ProgressModule { }
