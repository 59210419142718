<div class="container">

  <div class="row">
    <div class="col-lg-12 d-flex justify-content-end">
      <a [matTooltip]="aboutThisPageToolTip" matTooltipClass="custom-tooltip" class="help-text">
        <img style="width:25px;" src="../../../../../assets/img/icons/info-circle-fill.svg">
        <span class="tooltip-text" style="font-size:12px;"> About this page</span>
      </a>
    </div>
  </div>

  <div class="row pb-3">
    <div class="col-lg-6 d-flex justify-content-start align-items-center font-weight-bold h4">
      Employee Tracing
    </div>

    <div class="col-lg-6 d-flex justify-content-end">
      <a routerLink="/employee-management/add-team">
        <button class="btn btn-primary custom-primary-btn">Manage Departments</button>
      </a>
    </div>

  </div>
  <div class="row" *ngFor="let department of departments" class="col-lg-12">
    <app-team-table [department]="department"></app-team-table>
  </div>
</div>
