<div class="container mb-5">
  <div class="row">
      <div class="col-lg-12 d-flex justify-content-between">
        
        <div class="font-weight-bold h4 pb-3">{{profile.tenantName}} @Work 
       
         
      
      </div>

      <div class="">
        <a [matTooltip]="aboutThisPageToolTip" matTooltipClass="custom-tooltip" class="help-text">
        <img style="width:25px;" src="../../../../../assets/img/icons/info-circle-fill.svg"> <span class="tooltip-text" style="font-size:12px;"> About this page</span></a>
      </div>
    
    </div>  

   </div> 
   <div class="row">
       <div class="col-lg-5">
       
       
       
           <div class="card card-flat-bg-green max-height">

           <div class="d-flex justify-content-end">

            <div class="help-popover pr-1">
              <a
              [matTooltip]="symRepTodayToolTip"
              matTooltipClass="custom-tooltip"
              
              >
              
               
        
                         <img src="../../../../../assets/img/icons/info-circle-fill.svg"> 

            </a>
            
            </div>
          </div>

          <div class="card-body pt-0">

          
             <div class="card-title">Symptom Reports Today</div>    

             <p class="card-text mb-0 pr-5">{{symptoms.reportsLastDay}}/{{symptoms.totalUsers}} employees completed</p>
           </div>
           
    <!-- <app-progress-pie-chart [reportsLastDay]="reportsLastDay"></app-progress-pie-chart> -->
            

        </div>
      </div>
        <div class="col-lg-5">


          <div class="card card-flat-bg-purple max-height">

            <!-- <div class="d-flex justify-content-end">

              <div class="help-popover pr-1">
                <a  id="myPopover"  data-html="true" data-toggle="popover" data-placement="bottom">
               <img src="../../../../../assets/img/icons/info-circle-fill.svg"> </a> 
                
              
              </div>
            </div> -->

         <div class="card-body">
           <div class="card-title">Average Symptom Reports Completed in the last 7 Days</div>
           <p class="card-text mb-0 pr-5">{{symptoms.averageReportsLastWeek}}/{{symptoms.totalUsers}} employees completed</p>
          </div>

          <!-- <app-progress-pie-chart-two [reportsLastWeek]="reportsLastWeek"></app-progress-pie-chart-two> -->

       </div>
      </div>


   </div>
</div>

