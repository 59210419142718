 <div class="container">
   <div class="row">
     <div class="col-lg-6 mx-auto">
       <div class="card">
         <div class="card-body">

           <form [formGroup]="profileForm">
             <div class="form-group">
               <label for="exampleFirstName1">First Name</label>
               <input formControlName="firstName" type="text" class="form-control valid" id="firstName"
                      aria-describedby="exampleFirstName1" placeholder="Jane" [ngClass]="{'is-invalid': submitted && f.firstName.errors}">
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
              </div>

              <div class="form-group">
                <label for="examplelastName1">Last Name</label>
                <input formControlName="lastName" type="text" class="form-control valid" id="lastName"
                        aria-describedby="examplelastName1" placeholder="Doe" [ngClass]="{'is-invalid': submitted && f.lastName.errors}">
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
              </div>

              <div class="form-group">
                <label for="exampleEmail">Email</label>
                <input formControlName="email" type="email" class="form-control valid" id="exampleEmail1"
                        placeholder="example@example.com" [ngClass]="{'is-invalid': submitted && f.email.errors}">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Valid Email is required</div>
                </div>
              </div>

              <div class="form-group">
                <label for="examplePhone1">Phone</label>
                <input formControlName="phoneNumber" type="tel" class="form-control valid" id="examplePhone1"
                  placeholder="4165554343" [ngClass]="{'is-invalid': submitted && f.phoneNumber.errors}">
                <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                  <div *ngIf="f.phoneNumber.errors.pattern">Phone Number must be 10 digits</div>
                </div>
              </div>

              <div class="form-group">
                <label for="exampleSelect1">Team</label>
                <select formControlName="department" class="form-control valid" placeholder="Team" id="exampleSelect1"
                        [ngClass]="{'is-invalid': submitted && f.department.errors}">
                  <option value="" selected>-</option>
                  <option *ngFor="let department of departments" [value]="department.departmentId">{{department.departmentName}}</option>
                </select>
                <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                  <div *ngIf="f.department.errors.required">Department is required</div>
                </div>
              </div>

              <div class="form-group">
                <label for="exampleSelect1">Shift</label>
                <select  class="form-control valid">
                  <option value="" selected>-</option>
                  <option>Day Shift</option>
                  <option>Night Shift</option>
                </select>
                 
              </div>


              <div class="form-group">
                <label for="exampleSelect1">Role</label>
                <select formControlName="role" class="form-control valid" id="exampleSelect1"
                        [ngClass]="{'is-invalid': submitted && f.role.errors}">
                  <option value="" selected>-</option>
                  <option *ngFor="let role of roles" [value]="role.id">{{role.description}}</option>
                </select>
                <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                  <div *ngIf="f.role.errors.required">Role is required</div>
                </div>
              </div>

             <button (click)="onSubmit()" type="submit" class="btn btn-primary custom-primary-btn">Send Invite</button>
           </form>

         </div>
       </div>
     </div>
   </div>
 </div>
