import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Department } from 'src/app/_shared/_models/department';
import { Role } from 'src/app/_shared/_models/role';
import { AccountService } from 'src/app/_shared/_services/account.service';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit {

  public isLoading: boolean = false;
  public departmentList: Department[];
  public roles: Role[];

  constructor(private _atWorkService: AtWorkService,
              private _accountService: AccountService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this._getData();
  }

  private _getData(): void {
    forkJoin([
      this._atWorkService.getDepartmentList(),
      this._accountService.getRoles()
    ]).subscribe(([departments, roles]) => {
      this.departmentList = departments;
      this.roles = roles;

      this.isLoading = false;
    }, error => console.error(error));
  }

}
