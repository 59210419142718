import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatusReport } from 'src/app/_shared/_models/status-report';

@Component({
  selector: 'app-employee-status-details',
  templateUrl: './employee-status-details.component.html',
  styleUrls: ['./employee-status-details.component.css']
})
export class EmployeeStatusDetailsComponent implements OnInit {

  public statusReport: StatusReport;

  constructor(private _router: Router) {
    if (this._router.getCurrentNavigation() == null) {
      this._router.navigate(['/at-work']);
    }
    else {
      this.statusReport = <StatusReport>this._router.getCurrentNavigation().extras.state;
    }
  }

  ngOnInit(): void {
  }

}
