<div class="container">
  
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div class="card" [ngClass]="{'form-complete' : isSubmitted}">
        <div class="card-body ">

          <form [formGroup]="form">
            <div class="font-weight-bold h4">Symptom Report for: {{report.name}}</div>
            <div class="form-group">
              <label for="exampleFirstName1">Do you have any of the following new or worsening symptoms? Symptoms should not be
                chronic or related to other known causes or conditions.</label>
              <label class="mt-1 font-weight-bold">Select all that apply.</label>

              <div *ngFor="let symptom of symptoms"
                class="d-flex form-check form-inline pt-3 pb-3 mt-2 mb-2 pl-0 justify-content-between"
                style="background-color: aliceblue;">
                <div class="col-lg-10 d-flex">
                  <label class="form-check-label" for="defaultCheck1">
                    {{symptom.description}}
                  </label>
                </div>
                <div class="col-lg-2 col-2 d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" value="symptom.id" id="symptom.id"
                    (click)="onSymptomSelect(symptom)">
                </div>
              </div>
            </div>

            <div class="form-group form-inline">
              <div class="col-lg-9 d-flex">
                <label class="font-weight-bold">Have you travelled outside the country in the past 14 days?</label>
              </div>
              <div class="col">
                <mat-slide-toggle formControlName="travelled" class="example-margin">Yes</mat-slide-toggle>
              </div>
            </div>

            <div class="form-group form-inline">
              <div class="col-lg-9 d-flex">
                <label class="font-weight-bold">Have you had close contact with a confirmed or probable COVID-19
                  case?</label>
              </div>
              <div class="col">
                <mat-slide-toggle formControlName="contact" class="example-margin">Yes</mat-slide-toggle>
              </div>
            </div>

            <button type="submit" class="btn btn-primary custom-primary-btn" (click)="onSubmit()">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
