<div class="container mb-5">
    <div class="row">
        <div class="col-lg-12"><div class="title-text">Today's updates</div>
    </div>
    <div class="col-lg-5">
        <div class="card">
       <div class="card-body">
         <div class="card-title">Card title</div>
         
         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          
       </div>
     </div></div>
     <div class="col-lg-5">
        <div class="card">
       <div class="card-body">
         <div class="card-title">Card title</div>
          
         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
      
       </div>
     </div></div>

     <div class="col-lg-5">
        <div class="card">
       <div class="card-body">
         <div class="card-title">Card title</div>
          
         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          
       </div>
     </div></div>

</div>          
</div>