import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginChallenge } from 'src/app/_shared/_models/login-challenge';
import { AlertService } from 'src/app/_shared/_services/alert.service';
import { AuthenticationService } from 'src/app/_shared/_services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public submitted: boolean = false;
  public isLoading: boolean = false;
  public routeUrl: string;
  public invalidLoginAttempt: Boolean = false;

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });


  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _authService: AuthenticationService,
              private _alertService: AlertService) {
    // redirect if already logged in
    if (this._authService.currentUserValue) {
      if (this._authService.currentUserValue.role == 'User') {
        this._router.navigate(['/symptom-report/new']);
      }
      else {
        this._router.navigate(['/at-work']);
      }
    }

    this._route.queryParams.subscribe(params => {
      this.routeUrl = params['returnUrl'] || null;
    });
  }

  ngOnInit(): void {
  }

  public get f() {
    return this.loginForm.controls;
  }

  public onSubmit(): void {
    this.submitted = true;

    // return if not valid
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    let loginValue: LoginChallenge = new LoginChallenge(
      this.f.email.value, 
      this.f.password.value
    );
  
    this._authService.login(loginValue).subscribe(res => {
      this.isLoading = false;
      
      if (this.routeUrl == null) {
        if (this._authService.currentUserValue.role == 'User') {
          this.routeUrl = '/symptom-report/new';
        }
        else {
          this.routeUrl = '/at-work';
        }
      }
      else {
        if (this._authService.currentUserValue.role == 'User') {
          this.routeUrl = '/symptom-report/new';
        }
      }

      this._router.navigate([this.routeUrl]);
    }, 
    error => {
      this.isLoading = false;
      this._alertService.error("Invalid Email or Password", false);
      this.submitted = false;
      this.invalidLoginAttempt = true;
      this.loginForm.reset();
    });
  }
}
