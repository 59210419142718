import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Department } from 'src/app/_shared/_models/department';
import { Register } from 'src/app/_shared/_models/register';
import { Role } from 'src/app/_shared/_models/role';
import { AccountService } from 'src/app/_shared/_services/account.service';


@Component({
  selector: 'app-invite-employee-form',
  templateUrl: './invite-employee-form.component.html',
  styleUrls: ['./invite-employee-form.component.css']
})

export class InviteEmployeeFormComponent implements OnInit {

  @Input('departments') departments: Department[];
  @Input('roles') roles: Role[];

  public submitted: boolean = false;
  public isLoading: boolean = false;

  public profileForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    department: new FormControl('', [Validators.required]),
    role: new FormControl('', [Validators.required]),
  });

  constructor(private _router: Router,
              private _accountService: AccountService) {

  }

  ngOnInit(): void {

  }

  public get f() {
    return this.profileForm.controls;
  }

  

  public onSubmit(): void {
    this.submitted = true;

    // return if not valid
    if (this.profileForm.invalid) {
      return;
    }

    let profileValue: Register = new Register(
      this.f.email.value,
      this.f.phoneNumber.value,
      this.f.firstName.value,
      this.f.lastName.value,
      this.departments.find(dep => dep.departmentId == this.f.department.value).departmentId,
      this.roles.find(role => role.id == this.f.role.value).description
    );

    this._accountService.registerUser(profileValue).subscribe(res => {
      this.goBack();
    }, error => {
      console.error(error);
      // alert service here
      this.submitted = false;
    })
  }

  public goBack(): void {
    this._router.navigate(['/employee-management']);
  }
  
}
