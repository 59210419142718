import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/_shared/_services/alert.service';
import { FileService } from 'src/app/_shared/_services/file.service';
import * as moment from 'moment';

@Component({
  selector: 'app-symptom-audit-download',
  templateUrl: './symptom-audit-download.component.html',
  styleUrls: ['./symptom-audit-download.component.css']
})
export class SymptomAuditDownloadComponent implements OnInit {

  public progress: number;
  public message: string;

  public dateForm: FormGroup = new FormGroup({
    start: new FormControl(moment().subtract(7, 'days').startOf('day')),
    end: new FormControl(moment().startOf('day'))
  });

  constructor(private _fileService: FileService,
              private _alertService: AlertService) { }

  ngOnInit(): void {
  }

  public downloadAudit() {
    if (this.dateForm.get('start').value == null || this.dateForm.get('end').value == null) {
      console.log("invalid");

      // alert service here
      this._alertService.error("Please enter start and end dates.", false);
      return;
    }

    let startDate = (this.dateForm.get('start').value).format();
    let endDate = (this.dateForm.get('end').value).format();

    let startString = (this.dateForm.get('start').value).format('YYYYMMDD');
    let endString = (this.dateForm.get('end').value).format('YYYYMMDD');

    this._fileService.downloadAudit(startDate, endDate).subscribe((event) => {
      if (event.type === HttpEventType.DownloadProgress){
        this.progress = Math.round((100 * event.loaded) / event.total);
      }
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event, startString, endString);
      }
    });
  }

  private downloadFile(data: HttpResponse<Blob>, start: string, end: string) {
    const downloadFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none');
    document.body.appendChild(a);
    a.download = start + '_' + end + '_' + "audit_report.csv";
    a.href = URL.createObjectURL(downloadFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
}