import { Component, Input, OnInit } from '@angular/core';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
import { AccountService } from 'src/app/_shared/_services/account.service';
 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

  public userProfile: UserProfile;
  public isLoading: boolean = false;
  public name: string;
  public tenantName: string;

  constructor(private _accountService: AccountService) {

  }

  ngOnInit(): void {
    this.isLoading = true;

    this._accountService.getProfile().subscribe(res => {
      this.userProfile = res;
      this.isLoading = false;
      this.name = this.userProfile.firstName + " " + this.userProfile.lastName;
      this.tenantName = this.userProfile.tenantName;
    },
    error => console.error(error));

    
  }

}
