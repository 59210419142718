import { Moment } from "moment";
import { Department } from "./department";
import { Status } from "./status";
import { StatusReport } from "./status-report";
import { Role } from "./role";
import { CardioTrackUser } from "./cardio-track-user";

export class UserProfile {
    firstName: string;
    lastName: string;
    email: string;
    enabled: boolean;
    tenantName: string;
    homeCountry: string;
    homeTimeZone: string;
    gender: string;
    dateOfBirth: Moment;
    weight: number;
    height: number;
    weightUnit: string;
    heightUnit: string;
    phoneNumber: string;
    department: Department;
    statusHistory: StatusReport;
    statusReport: StatusReport;
    status: Status;
    role: Role;
    cardioTrackUser: CardioTrackUser;

    [key: string]: any;

    constructor(_firstName: string, _lastName: string, _email: string,
                _phoneNumber: string, _department: Department, _status: Status, _role: Role, _enabled: boolean) {
        this.firstName = _firstName;
        this.lastName = _lastName;
        this.email = _email;
        this.phoneNumber = _phoneNumber;
        this.department = _department;
        this.status = _status;
        this.role = _role;
        this.enabled = _enabled;
    }
}