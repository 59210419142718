import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Department } from 'src/app/_shared/_models/department';
import { StatusReport } from 'src/app/_shared/_models/status-report';

@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.css']
})
export class TeamCardComponent implements OnInit {

  @Input('departments') departments: Department[];
  public aboutThisPageToolTip: string;

  constructor(private _router: Router) {

    this.aboutThisPageToolTip = "What does this page show?\n\nThe Employee Tracing page displays the status of each department in your workforce by organizing the employees that belong to each respective team, shift or department. As an employer or manager, you are able to see how the status of one individual employee can affect the rest of the employees within their group.\n\nAdjust the number of employees shown in each department by using the “Items per page” drop-down menu in the bottom right corner of each department card.\n\nHow can I use this information?\n\nYou can view an individual employee’s symptom log at any time by clicking on the adjacent notepad icon in the ‘Details’ column. Understanding their symptom progression over time will allow you to make informed decisions to protect the rest of your workforce.";

   }

  ngOnInit(): void {
  }

  public goToDetails(report: StatusReport): void {
    this._router.navigate(['/at-work/employee-status-details'], {state: report});
  }

}
