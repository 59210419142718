import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { API_URL } from 'src/app/constants';
import { AlertService } from 'src/app/_shared/_services/alert.service';

@Component({
  selector: 'app-user-upload',
  templateUrl: './user-upload.component.html',
  styleUrls: ['./user-upload.component.css']
})
export class UserUploadComponent implements OnInit {

  public progress: number;
  public message: string;

  @Output() public onUploadFinished = new EventEmitter();

  constructor(private _alertService: AlertService,
              private _http: HttpClient,
              private _router: Router) { }

  ngOnInit(): void {
  }

  public uploadFile = (files: any) => {
    console.log(files);
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this._http.post(`${API_URL}/Register/UploadCSV`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
          //this._alertService.success("File has been uploaded successfully", true);
          //this._router.navigate(['/employee-management']);
          window.location.reload();
        }
      }, error => {
        console.error(error);
        this._alertService.error(error.error);
      });
  }

}
