<div class="container-fluid p-0 bg-light h-100 overflow-hidden">
  <div class="row h-100 align-items-center justify-content-center">
    <div class="col-xl-3 col-lg-3 col-md-4 col-10 text-center p-3 login-wrapper">
      <img src="https://achuhealth.com/images/achu@work.png" width="150" class="mt-5" alt="">
      <p>By Achu Health™</p>
      

      <form [formGroup]="loginForm" class="p-4">
        <app-alert></app-alert>
        <!-- <div *ngIf="invalidLoginAttempt">
          <small class="text-danger">Invalid Email or Password</small>
        </div> -->

        <div class="form-group">
          <label class="d-flex">Email</label>
          <input type="email" class="form-control valid" formControlName="email" id="email" aria-describedby="email"
                 placeholder="example@example.com" [ngClass]="{'is-invalid': submitted && f.email.errors}">
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Valid Email is required</div>
          </div>
        </div>

        <div class="form-group">
          <label class="d-flex">Password</label>
          <input type="password" formControlName="password" class="form-control" id="password" placeholder="******" 
                 [ngClass]="{'is-invalid': submitted && f.password.errors}">
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
        </div>
        <div class="forgot-pw-style text-right">
          <a routerLink="/forgot-password"><small>Forgot Password?</small></a>
        </div>
        <button (click)="onSubmit()" type="submit" class="btn btn-primary custom-primary-btn px-5 mt-3">Sign In</button>
      </form>
    </div>
  </div>
</div>
