import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { User } from '../_shared/_models/user';
import { AlertService } from '../_shared/_services/alert.service';
import { AuthenticationService } from '../_shared/_services/authentication.service';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private _authService: AuthenticationService,
              private _router: Router,
              private _alertService: AlertService) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    if (this._authService.isAuthenticated()) {
      const expectedRoles = route.data.expectedRoles;
      const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
      const token = user.accessToken;
      const tokenPayload = <any>decode(token);

      if (tokenPayload.role) {
        if (expectedRoles.some((r: any) => tokenPayload.role.includes(r))) {
          return true;
        }
      }

      console.log('here1');
      this._alertService.error("Unable to login");
      this._router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      
      return false;
    }

    console.log('here2');
    this._alertService.error("Unable to login");
    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    
    return false;
  }
  
}
