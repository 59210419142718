import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EmployeeSymptomDetailsComponent } from './employee-symptom-details-card/employee-symptom-details-card.component';
import { EmployeeStatusDetailsCardComponent } from './employee-status-details-card/employee-status-details-card.component';
 


@NgModule({
  declarations: [
     EmployeeSymptomDetailsComponent,
     EmployeeStatusDetailsCardComponent
      
   ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
     EmployeeSymptomDetailsComponent,
     EmployeeStatusDetailsCardComponent
  ]
})
export class DetailsModule { }
