import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Department } from 'src/app/_shared/_models/department';
import { StatusReport } from 'src/app/_shared/_models/status-report';

@Component({
  selector: 'app-team-table',
  templateUrl: './team-table.component.html',
  styleUrls: ['./team-table.component.css']
})
export class TeamTableComponent implements OnInit, AfterViewInit {

  @Input('department') department: Department;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('sort') sort: MatSort;

  public displayedColumns: string[] = ['name', 'statusLevel', '_lastReportString', 'details'];
  public dataSource: MatTableDataSource<StatusReport> = new MatTableDataSource<StatusReport>();

  constructor(private _router: Router) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.department.statusReports);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public goToDetails(report: StatusReport): void {
    this._router.navigate(['/at-work/employee-status-details'], {state: report});
  }

}
