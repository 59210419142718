import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TeamCardComponent } from './team/team-card.component';
import { AddTeamFormComponent } from './forms/add-team-form/add-team-form.component';
import { TeamListComponent } from './team-list/team-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamTableComponent } from './team-table/team-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { NgbTimepicker, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    TeamCardComponent,
    AddTeamFormComponent,
    TeamListComponent,
    TeamTableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCheckboxModule,
    NgbTimepickerModule
  ],
  exports: [
    TeamCardComponent,
    AddTeamFormComponent,
    TeamListComponent
  ]
})


export class TeamModule { }
