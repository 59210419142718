<div class="card">
    <div class="card-body pb-0">
        <div class="sub-title-text font-weight-bold">{{department.departmentName}}</div>
        <div *ngIf="department.departmentManagers?.length === 0" class="manager-warning">This department doesn't have a manager. You may not recieve important notifications.</div>

        <table mat-table #sort="matSort" matSort [dataSource]="dataSource" class="table">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let report">{{report.name}}</td>
            </ng-container>

            <ng-container matColumnDef="_lastReportString">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Report</th>
                <td mat-cell *matCellDef="let report">{{report._lastReportString}}</td>
            </ng-container>

            <ng-container matColumnDef="statusLevel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Status</th>
                <td mat-cell *matCellDef="let report">
                    <button *ngIf="report.statusLevel == 1" type="button" class="btn btn-success flag" disabled>Cleared</button>
                    <button *ngIf="report.statusLevel == 2" type="button" class="btn btn-warning flag" disabled>Pending</button>
                    <button *ngIf="report.statusLevel == 3" type="button" class="btn btn-danger flag" disabled>Quarantine</button>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="shift">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
                <td mat-cell *matCellDef="let report">Day Shift</td>
            </ng-container> -->

            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef>Details</th>
                <td mat-cell *matCellDef="let report" class="link" (click)="goToDetails(report)">
                    <img src="assets/img/icons/details_1.svg">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
    </div>
</div>
