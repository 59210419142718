import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function PasswordValidator(controlName: string){
    return(formGroup: FormGroup) => {
        
        const control = formGroup.controls[controlName];

        if(control.errors){
            return;
        }
        var upper = new RegExp("(?=.*[a-z])");
        var lower = new RegExp("(?=.*[A-Z])");
        var number = new RegExp("(?=.*[0-9])");
    
        var upperAndLower =  true;
        var longEnough =  true;
        var containsNumber =  true;

        if(control.value.length < 6){
            longEnough = false;
        }

        if (upper.test(control.value) === false || lower.test(control.value) === false) {
            upperAndLower = false;
        }

        if (number.test(control.value) == false) {
            containsNumber = false;
        }

        if(upperAndLower == false || longEnough == false || containsNumber == false){
            control.setErrors({
                length : longEnough,
                number : containsNumber,
                lowerAndUpper : upperAndLower
            });
        }
        else{
            control.setErrors(null);
        }
        
    }
}