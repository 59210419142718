<div class="container mb-5">
    <div class="row">
        <div class="col-lg-12"><div class="title-text">This week at a glance</div></div>  
     </div> 
     <div class="row">
         <div class="col-lg-5">
             <div class="card card-flat-bg-green">
            <div class="card-body">
              <div class="card-title">Card title</div>
              <div class="card-subtitle mb-2">Card subtitle</div>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="card-link">view more <img src="assets/img/icons/chevron-right.svg"></a>
            </div>
          </div></div>
          <div class="col-lg-5">
            <div class="card card-flat-bg-purple">
           <div class="card-body">
             <div class="card-title">Card title</div>
             <div class="card-subtitle mb-2">Card subtitle</div>
             <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
             <a href="#" class="card-link">view more <img src="assets/img/icons/chevron-right.svg"></a>
           </div>
         </div></div>
     </div>
</div>