import { Component, Input, OnInit } from '@angular/core';
import { StatusReportDetail } from 'src/app/_shared/_models/status-report-detail';

@Component({
  selector: 'app-employee-symptom-details-card',
  templateUrl: './employee-symptom-details-card.component.html',
  styleUrls: ['./employee-symptom-details-card.component.css']
})
export class EmployeeSymptomDetailsComponent implements OnInit {

  @Input('status') status: StatusReportDetail;

  constructor() { }

  ngOnInit(): void {
  }

}
