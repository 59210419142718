<div class="container mb-5">
  <div class="row">
    <div class="col-lg-10">
      <div class="card">
        <div class="card-body">
          <div class="sub-title-text font-weight-bold">{{statusReport.name}}</div>
          <p>
            <button *ngIf="statusReport.statusLevel == 1" type="button" class="btn btn-success flag"
              disabled>Clear</button>
            <button *ngIf="statusReport.statusLevel == 2" type="button" class="btn btn-warning flag"
              disabled>Pending</button>
            <button *ngIf="statusReport.statusLevel == 3" type="button" class="btn btn-danger flag"
              disabled>Confirmed</button>
            <span class="pl-4" *ngIf="statusReport.statusLevel == 3">
              Status: {{statusReport.statusTitle}} {{statusReport.daysQuarantined}}/14
            </span>
            <span class="pl-4" *ngIf="statusReport.statusLevel != 3">Status: {{statusReport.statusTitle}}</span>
          </p>
          <p *ngIf="statusReport.statusLevel == 3">
            Quarantine Start: {{statusReport._quarantineStartString}}
            <br>
            Quarantine End: {{statusReport._quarantineEndString}}
            <br>
            Days Remaining: {{statusReport.daysRemaining}}
          </p>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
                <th scope="col">Description</th>
                <th scope="col">Report Submitted</th>
                <th scope="col">Details</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let detail of statusReport.statusDetails">
                <td>{{detail._statusDateString}}</td>
                <td>
                  <button *ngIf="detail.statusLevel == 1" type="button" class="btn btn-success flag"
                    disabled>Clear</button>
                  <button *ngIf="detail.statusLevel == 2" type="button" class="btn btn-warning flag"
                    disabled>Pending</button>
                  <button *ngIf="detail.statusLevel == 3" type="button" class="btn btn-danger flag"
                    disabled>Confirmed</button>
                </td>
                <td>{{detail.statusTitle}}</td>
                <td>{{detail.reportSubmitted ? 'Yes' : 'No'}}</td>
                <td >

                  <div *ngIf="detail.reportSubmitted; else noImage" (click)="goToSymptomReport(detail)" class="link">
                    <img src="assets/img/icons/details_1.svg">

                  </div>
                  
                 <ng-template #noImage>-</ng-template>
                
                
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>