<div class="container-fluid p-0 bg-light h-100 overflow-hidden">
  <div class="row h-100 align-items-center justify-content-center">
    <div class="col-xl-3 col-lg-3 col-md-4 col-10 text-center p-3 login-wrapper">
      <img src="https://achuhealth.com/images/achu@work.png" width="150" class="mt-5" alt="">
      <p>By Achu Health™</p>
      <app-alert></app-alert>

      <p>Welcome to @Work!</p>
      <p>Please follow the links below to download our app.</p>
      <div>
        <a href="https://apps.apple.com/ca/app/work-by-achu-health/id1516978746" class="app-link">
            <img src="../../../../assets/img/App_Store_Badge_US_Black.png">
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.datapult.atwork" class="app-link">
            <img src="../../../../assets/img/Google_Play_Badge_US.png">
        </a>
      </div>
    </div>
  </div>
</div>
