import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch, PasswordValidator } from 'src/app/_helpers/form-validators';
import { ResetPassword } from 'src/app/_shared/_models/reset-password';
import { AccountService } from 'src/app/_shared/_services/account.service';
import { AlertService } from 'src/app/_shared/_services/alert.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  private _email: string;
  private _code: string;
  public submitted: boolean = false;

  public passwordForm: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _router: Router,
              private _route: ActivatedRoute,
              private _accountService: AccountService,
              private _alertService: AlertService) {
    this._route.queryParams.subscribe(params => {
      this._code = params['code'];
      this._email = params['email'];
    });
  }

  ngOnInit(): void {
    this.passwordForm = this._formBuilder.group({
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    },
    {
      validator: [MustMatch('newPassword', 'confirmNewPassword'), PasswordValidator('newPassword')]
    });
  }

  public get f() {
    return this.passwordForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    let value = new ResetPassword();
    value.code = this._code;
    value.email = this._email;
    value.newPassword = this.f.newPassword.value;

    this._accountService.resetPassword(value).subscribe(res => {
      this._alertService.success("Your password has been reset successfully!", true);
      this._router.navigate(['/reset-success']);
    },
    error => {
      this._alertService.error("Unable to reset password, please try again.", true);
      this._router.navigate(['/forgot-password']);
    });
  }

}
