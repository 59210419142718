import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Department } from 'src/app/_shared/_models/department';
import { ManagerProfile } from 'src/app/_shared/_models/manager-profile';
import { AlertService } from 'src/app/_shared/_services/alert.service';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.css']
})
export class TeamListComponent implements OnInit {

  @Input('departments') departments: Department[];
  @Input('managers') managers: ManagerProfile[];

  public submitted: boolean = false;
  // public departmentListToolTip: string;

  public editForm: FormGroup = new FormGroup({
    depName: new FormControl('', [Validators.required])
  });

  public selectedDepartment: Department;
  public time: NgbTimeStruct = {hour: 7, minute: 0, second: 0};

  constructor(private _atWorkService: AtWorkService,
              private _alertService: AlertService) {

    // this.departmentListToolTip = "";
  }

  ngOnInit(): void {
  }

  public selectDepartment(department: Department) {
    this.selectedDepartment = department;
    this.editForm.get('depName').setValue(department.departmentName);
    this.time = { hour: parseInt(department.reminderLocalTimeString.slice(0,2)), minute: parseInt(department.reminderLocalTimeString.slice(3,5)), second: 0 };

    this.managers.forEach(manager => {
      if ((this.selectedDepartment.departmentManagers.filter(man => man.email == manager.email).length > 0)) {
        manager._checked = true;
      } else {
        manager._checked = false;
      }
    });
  }

  public onCheckChange(manager: ManagerProfile) {
    manager._checked = !manager._checked;
  }

  public get f() {
    return this.editForm.controls;
  }

  public onSubmit(): void {
    this.submitted = true;

    // return if not valid
    if (this.editForm.invalid) {
      return;
    }

    this.selectedDepartment.departmentName = this.f.depName.value;

    let hourString: string = (this.time.hour).toString().length == 1 ? "0"+(this.time.hour).toString() : (this.time.hour).toString();
    let minuteString: string = (this.time.minute).toString().length == 1 ? "0"+(this.time.minute).toString() : (this.time.minute).toString();
    this.selectedDepartment.reminderLocalTimeString = hourString + ":" + minuteString;

    this._atWorkService.editDepartment(this.selectedDepartment).subscribe(res => {
      if (res) {
        this._alertService.success("Department edited!");
      }
    },
    error => {
      console.error(error);
      this._alertService.error(error.error.error);
      this.submitted = false;
    });
  }
  
  public onDelete(): void {

      this._atWorkService.deleteDepartment(this.selectedDepartment).subscribe(res => {
        if (res) {
          this._alertService.success("Department deleted!");
          window.location.reload();
        }
      },
      error => {
        console.error(error);
        this._alertService.error(error.error.error);
      });
  }

  public onManagerSubmit(): void {
    let selectedManagers = this.managers.filter(manager => manager._checked === true);

    this._atWorkService.setDepartmentManagers(this.selectedDepartment.departmentId, selectedManagers).subscribe(res => {
      if (res) {
        this._alertService.success("Managers were set successfully!");
        window.location.reload();
      }
    },
      error => {
        console.error(error);
        this._alertService.error(error.error.error);
      });
  }
}
