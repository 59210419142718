import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StatusReport } from 'src/app/_shared/_models/status-report';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserProfile } from 'src/app/_shared/_models/user-profile';

@Component({
  selector: 'app-case-status-card',
  templateUrl: './case-status-card.component.html',
  styleUrls: ['./case-status-card.component.css']
})
export class CaseStatusCardComponent implements OnInit {

  @Input('profile') userProfile: UserProfile;

  @ViewChild('sortCleared') sortCleared: MatSort;
  @ViewChild('paginatorCleared') paginatorCleared: MatPaginator;
  @ViewChild('sortPending') sortPending: MatSort;
  @ViewChild('paginatorPending') paginatorPending: MatPaginator;
  @ViewChild('sortFlagged') sortFlagged: MatSort;
  @ViewChild('paginatorFlagged') paginatorFlagged: MatPaginator;
  @ViewChild('sortRequired') sortRequired: MatSort;
  @ViewChild('paginatorRequired') paginatorRequired: MatPaginator;
  
  public statusReports: StatusReport[];
  
  public clearedReports: StatusReport[];
  public pendingReports: StatusReport[];
  public flaggedReports: StatusReport[];
  public requiredReports: StatusReport[];

  public displayedColumnsCleared: string[] = ['name', '_lastReportString', 'statusTitle','details'];
  public displayedColumnsPending: string[] = ['name', '_lastReportString', 'statusTitle', 'details'];
  public displayedColumnsFlagged: string[] = ['name', '_quarantineStartString', '_quarantineEndString', 'daysRemaining', 'details'];
  public displayedColumnsRequired: string[] = ['name', '_lastReportString', 'statusTitle','completeReport', 'details'];

  public dataSourceCleared: MatTableDataSource<StatusReport> = new MatTableDataSource<StatusReport>();
  public dataSourcePending: MatTableDataSource<StatusReport> = new MatTableDataSource<StatusReport>();
  public dataSourceFlagged: MatTableDataSource<StatusReport> = new MatTableDataSource<StatusReport>();
  public dataSourceRequired: MatTableDataSource<StatusReport> = new MatTableDataSource<StatusReport>();

  public requireCheckinToolTip: string;
  public clearedCheckinToolTip: string;
  public pendingCasesToolTip: string;
  public confirmedCasesToolTip: string;


  constructor(private _atWorkService: AtWorkService,
              private _router: Router) { 

                this.requireCheckinToolTip = "The employees shown in this section are pending approval to attend work today based on the completion of their daily symptom report. Given they submit a symptom-free report, they will be eligible to attend work.\n\n Tip: These employees will receive a reminder notification from the @Work mobile app to complete their report. Check-in with them to make sure they complete this prior to entering the workplace.";
                this.clearedCheckinToolTip = "The employees shown in this category are cleared, meaning they are eligble to attend work.\n\n Employees shown here could be any of the following:\n\n 1. Employees who have completed a survey today, who answered no to all questions and reported no symptoms.\n\n 2. Employees who have just rpeorted a negative test result.\n\n 3. Employees who have complete their mandatory quarantine period.";
                this.pendingCasesToolTip = "The employees shown in the pending category are not eligible to attend work.\n\n 1. Employees who have come in close contact with a positive case or have received a potential exposure notification.\n\n 2. Employees who have reported symptoms indicating a possible infection.\n\n 3. Employees who answered yes to any of the travel questions on the survey.\n\n 4. Employees who are awaiting test results.";
                this.confirmedCasesToolTip = "The employees shown in this category have either just submitted a positive test result or are currently in quarantine. You can see the start and end date of their quarantine period, as well as a countdown of the number of days remaining.";


              }

  ngOnInit(): void {
    this._atWorkService.getStatusReports().subscribe(res => {
      this.statusReports = res;

      this.statusReports.forEach(report => {
        report._lastReportString = report.lastReport ? moment(report.lastReport).format("YYYY/MM/DD") : 'N/A';
        report._firstReportString = report.firstReport ? moment(report.firstReport).format("YYYY/MM/DD") : 'N/A';
        report._quarantineStartString = report.quarantineStart ? moment(report.quarantineStart).format("YYYY/MM/DD") : 'N/A';
        report._quarantineEndString = report.quarantineEnd ? moment(report.quarantineEnd).format("YYYY/MM/DD") : 'N/A';

        if (report.passedTempCheck != null) {
          report._tempCheckString = report.passedTempCheck ? 'PASSED' : 'FAILED';
        }
        else {
          report._tempCheckString = '-';
        }

        report.statusDetails.forEach(detail => {
          detail._statusDateString = moment(detail.statusDate).format("YYYY/MM/DD");
        });
      });

      if (this.userProfile.requireTemperatureCheck) {
        this.displayedColumnsCleared = ['name', '_lastReportString', 'statusTitle', 'temperatureCheckStatus', 'details'];
        this.displayedColumnsPending = ['name', '_lastReportString', 'statusTitle', 'temperatureCheckStatus', 'details'];
      }
      
      this.clearedReports = this.statusReports.filter(report => report.statusLevel == 1);
      this.dataSourceCleared = new MatTableDataSource(this.clearedReports);
      this.dataSourceCleared.sort = this.sortCleared;
      this.dataSourceCleared.paginator = this.paginatorCleared;

      this.pendingReports = this.statusReports.filter(report => report.statusLevel == 2 && report.statusId != 5 && report.statusId != 10);
      this.dataSourcePending = new MatTableDataSource(this.pendingReports);
      this.dataSourcePending.sort = this.sortPending;
      this.dataSourcePending.paginator = this.paginatorPending;

      this.requiredReports = this.statusReports.filter(report => report.statusId == 5 || report.statusId == 10);
      this.dataSourceRequired = new MatTableDataSource(this.requiredReports);
      this.dataSourceRequired.sort = this.sortRequired;
      this.dataSourceRequired.paginator = this.paginatorRequired;

      this.flaggedReports = this.statusReports.filter(report => report.statusLevel == 3);
      this.dataSourceFlagged = new MatTableDataSource(this.flaggedReports);
      this.dataSourceFlagged.sort = this.sortFlagged;
      this.dataSourceFlagged.paginator = this.paginatorFlagged;
    });
  }

  public goToDetails(report: StatusReport): void {
    this._router.navigate(['/at-work/employee-status-details'], {state: report});
  }

  public goToSurvey(report: StatusReport): void {
    this._router.navigate(['symptom-report/employee-new'], {state: report});
  }

}
