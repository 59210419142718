export class Symptom {
    id: number;
    description: string;

    [key: string]: any;
}

export class SymptomReport {
    id?: number;
    userEmail?: string;
    contactWithCase: boolean;
    travelled: boolean;
    symptoms: Symptom[];

    [key: string]: any;
}