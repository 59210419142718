<div class="container mb-5">
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-end">
      <a [matTooltip]="aboutThisPageToolTip" matTooltipClass="custom-tooltip" class="help-text">
        <img style="width:25px;" src="../../../../../assets/img/icons/info-circle-fill.svg">
        <span class="tooltip-text" style="font-size:12px;"> About this page</span>
      </a>
    </div>
  </div>
  <div class="row pb-3">
    <div class="col-lg-6 d-flex justify-content-start align-items-center  font-weight-bold h4">
      All Employees
    </div>
    <div class="col-lg-6 d-flex justify-content-end">
      <a routerLink="/employee-management/invite-employee">
        <button class="btn btn-primary custom-primary-btn">Invite Employee</button>
      </a>
    </div>
  </div>
  <app-user-upload></app-user-upload>
  <div class="row">
    <div class="col-lg-12">
      <mat-card class="card">

        <div class="d-flex align-items-end flex-column" >
          <input matInput (keyup)="applyFilter($event)" placeholder="Search Employee">
          <a [matTooltip]="allEmployeesToolTip" matTooltipClass="custom-tooltip" class="pt-3">
            <img style="width:25px;" src="../../../../../assets/img/icons/info-circle-fill.svg"></a>
        </div>


        <table mat-table matSort [dataSource]="dataSource" class="table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let user" [ngClass]="{disabled: !user.enabled}">{{user.name}}</td>
          </ng-container>

          <ng-container matColumnDef="statusLevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Status</th>
            <td mat-cell *matCellDef="let user">
              <button *ngIf="user.statusHistory.statusLevel == 1" type="button" class="btn btn-success flag" disabled>Cleared</button>
              <button *ngIf="user.statusHistory.statusLevel == 2" type="button" class="btn btn-warning flag" disabled>Pending</button>
              <button *ngIf="user.statusHistory.statusLevel == 3" type="button" class="btn btn-danger flag" disabled>Quarantine</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let user" [ngClass]="{disabled: !user.enabled}">{{user.email}}</td>
          </ng-container>

          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let user" [ngClass]="{disabled: !user.enabled}">{{user.phoneNumber | phone:'CA'}}</td>
          </ng-container>

          <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
            <td mat-cell *matCellDef="let user" [ngClass]="{disabled: !user.enabled}">{{user.department.departmentName}}</td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let user" class="link" (click)="goToDetails(user.statusReport)">
              <img src="assets/img/icons/details_1.svg">
            </td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let user" class="link" (click)="goToEditUser(user)">
              <img src="assets/img/icons/edit.png">
            </td>
          </ng-container>

          <ng-container matColumnDef="qrCode">
            <th mat-header-cell *matHeaderCellDef>Qr Code</th>
            <td mat-cell *matCellDef="let user">
              <button type="button" class="btn btn-primary" (click)="selectUser(user)"
              data-toggle="modal" data-target="#qrCodeModal">View</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
</div>

<!-- Qr Code Modal -->
<div class="modal fade" id="qrCodeModal" tabindex="-1" role="dialog" aria-labelledby="qrCodeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="qrCodeModalLabel">Qr Code ({{selectedUser?.firstName}} {{selectedUser?.lastName}})</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src={{selectedUser?.qrImageUrl}} class="qrImage" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <a href={{selectedUser?.qrImageUrl}} download={{selectedUser?.qrImageName}}>
          <button type="submit" class="btn btn-primary">Download</button>
        </a>
      </div>
    </div>
  </div>
</div>