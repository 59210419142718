import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Department } from 'src/app/_shared/_models/department';
import { ManagerProfile } from 'src/app/_shared/_models/manager-profile';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.css']
})
export class AddTeamComponent implements OnInit {

  public isLoading: boolean = false;
  public departments: Department[];
  public managers: ManagerProfile[];

  constructor(private _atWorkService: AtWorkService) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this._getData();
  }

  private _getData() {
    forkJoin([
      this._atWorkService.getDepartmentList(),
      this._atWorkService.getManagerProfiles()
    ]).subscribe(([departments, managers]) => {
      this.departments = departments;
      this.managers = managers;

      this.isLoading = false;
    }, error => console.error(error));
  }

}
