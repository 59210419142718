import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Department } from 'src/app/_shared/_models/department';
import { AlertService } from 'src/app/_shared/_services/alert.service';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';

@Component({
  selector: 'app-add-team-form',
  templateUrl: './add-team-form.component.html',
  styleUrls: ['./add-team-form.component.css']
})
export class AddTeamFormComponent implements OnInit {

  public submitted: boolean = false;
  public addTeamToolTip: string;
  public aboutThisPageToolTip: string;

  public teamForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required])
  });

  constructor(private _atWorkService: AtWorkService,
              private _alertService: AlertService) { 

                this.addTeamToolTip = "How can I create a new department?\n\nCreate a new department by entering the name and clicking ‘Create’. The new department should automatically appear in the list of all departments below. If you ever want to edit the name of a department, click on the ‘edit’ icon and type in the new name.\n\nHow can I add employees to a new department?\n\nYou can add employees to your new department on the ‘Manage Employees’ tab on the left side of the dashboard. Locate the employee you would like to add to the department by searching or scrolling to find them. Click on the Edit icon in the far-right column. Scroll down to the ‘Team’ menu and select the department you would like to add them to using the drop-down menu. Click update to save the changes.";
                this.aboutThisPageToolTip = "What does this page show? \n\nThe Manage Departments tab shows the organization of your workforce and allows an administrator to view, edit and create new teams, shifts and departments.\n\nHow can I use this information?\n\nIn order to protect your workforce and maximize the safety of your employees, it is important to organize your customizable dashboard in a way that reflects the organization of your business.";

              }

  ngOnInit(): void {

  }

  public get f() {
    return this.teamForm.controls;
  }

  public onSubmit(): void {
    this.submitted = true;

    // return if not valid
    if (this.teamForm.invalid) {
      return;
    }

    let departmentValue: Department = new Department(this.f.name.value);

    this._atWorkService.addDepartment(departmentValue).subscribe(res => {
      if (res) {
        window.location.reload();
      }
    },
    error => {
      console.error(error);
      //this._alertService.error("")
      this.submitted = false;
      this.teamForm.reset();
    });
  }

}
