import { ManagerProfile } from "./manager-profile";

export class Department {
    departmentId: number;
    departmentName: string;
    userCount: number;
    isDefault: boolean;
    statusLevel: number;
    reminderLocalTimeString: string;
    departmentManagers?: ManagerProfile[];

    [key: string]: any;

    constructor(_name: string) {
        this.departmentName = _name;
    }
}