import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from 'src/app/constants';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private _http: HttpClient) { }

  public downloadAudit(startDate: string, endDate: string): Observable<HttpEvent<Blob>> {
    return this._http.get(`${API_URL}/Symptoms/Audit?startDate=${startDate}&endDate=${endDate}`, {
      reportProgress: true,
      responseType: 'blob',
      observe: 'response'
    });
  }
}
