import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from 'src/app/constants';
import { ForgotPassword } from '../_models/forgot-password';
import { Register } from '../_models/register';
import { ResetPassword } from '../_models/reset-password';
import { Role } from '../_models/role';
import { UserProfile } from '../_models/user-profile';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public profile: UserProfile; 

  constructor(private _http: HttpClient) { }

  public forgotPassword(value: ForgotPassword) {
    return this._http.post(`${API_URL}/Authentication/ForgotPassword`, value);
  }

  public resetPassword(value: ResetPassword) {
    return this._http.post(`${API_URL}/Authentication/ResetPassword`, value);
  }

  public resendWelcome(value: ForgotPassword) {
    return this._http.post(`${API_URL}/Authentication/ResendWelcome`, value);
  }

  public getProfile(): Observable<UserProfile> {
    return this._http.get<UserProfile>(`${API_URL}/Account/Profile`);
  }

  public registerUser(user: Register) {
    return this._http.post<Register>(`${API_URL}/Register`, user);
  }

  public getRoles(): Observable<Role[]> {
    return this._http.get<Role[]>(`${API_URL}/Roles`);
  }
  
}
