<div class="row justify-content-end" style="margin-bottom:15px;">
    <div class="col-lg-3 d-flex justify-content-end">
        <span class="upload" *ngIf="progress > 0">
        {{progress}}%
        </span>
        <span class="upload" *ngIf="message">
        {{message}}
        </span>
    </div>
    <div class="col-lg-3 d-flex justify-content-end">
        <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;">
        <button type="button" class="btn btn-success custom-success-btn" (click)="file.click()">Bulk Upload</button>
    </div>
</div>