import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weekly-update-card',
  templateUrl: './weekly-update-card.component.html',
  styleUrls: ['./weekly-update-card.component.css']
})
export class WeeklyUpdateCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
