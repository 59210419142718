<nav id="sidebar">
    <div class="sidebar-header pl-3 pr-3">
       <img src="https://achuhealth.com/images/logo.png" class="img-fluid pt-4" > <a class="pt-4 d-md-none d-block" id="sidebarClose">
        <span aria-hidden="true close">&#9587;</span>
       </a>
    </div>

    <ul class="list-unstyled components pl-2 pr-2">
                
        <!-- <li>
            <a  [routerLink]="['/dashboard']" data-toggle="collapse" aria-expanded="true" >
                
                <img src="assets/img/icons/circle.svg">   Dashboard</a>
             
        </li> -->
<!-- 
        <li>

            <a href="#employeeWellnessSubMenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapse show collapsed">

                <img src="assets/img/icons/active.svg">   Employee Wellness
            </a>

            <ul class="list-unstyled collapse" id="employeeWellnessSubMenu">
                <li>
                    <a routerLink="at-work">Engagement</a>
                </li>
                <li>
                    <a routerLink="at-work/teams">Wellness Overview</a>
                </li>
                <li>
                    <a routerLink="employee-management">Goals</a>
                </li>
                
                
                
            </ul>
        </li> -->
       
        <li *ngIf="currentUserRole != 'User'">

            <a href="#atWorkSubMenu" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle collapse show collapsed">

                <img src="assets/img/icons/active.svg">   @Work
            </a>

            <ul class="list-unstyled show" id="atWorkSubMenu">
                <li>
                    <a class="activeSubLink" routerLink="at-work">Overview</a>
                </li>
                <li>
                    <a routerLink="at-work/teams">Employee Tracing</a>
                </li>
                <li>
                    <a routerLink="employee-management">Manage Employees</a>
                </li>
        
                <li>
                    <a routerLink="employee-management/add-team">Manage Departments</a>
                </li>
                <li>
                    <a routerLink="reports">Reports</a>
                </li>
            </ul>
        </li>
        <li>
            <a class="link" href="symptom-report/new">Symptom Survey</a>
        </li>
        <li>
            <a class="link" href="http://achuhealth.zendesk.com">Support</a>
        </li>
        <li>
            <a class="link" (click)="logout()">Logout</a>
        </li>
      

    </ul>

     
</nav>