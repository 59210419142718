import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatusReportDetail, SymptomReport } from 'src/app/_shared/_models/status-report-detail';

@Component({
  selector: 'app-symptom-report-details',
  templateUrl: './symptom-report-details.component.html',
  styleUrls: ['./symptom-report-details.component.css']
})
export class SymptomReportDetailsComponent implements OnInit {

  public status: StatusReportDetail;

  constructor(private _router: Router) {
    if (this._router.getCurrentNavigation() == null) {
      this._router.navigate(['/at-work']);
    }
    else {
      this.status = <StatusReportDetail>this._router.getCurrentNavigation().extras.state;
    }
  }

  ngOnInit(): void {
  }

}
