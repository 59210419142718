import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 import { RouterModule } from '@angular/router';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { InviteEmployeeFormComponent } from './forms/invite-employee-form/invite-employee-form.component';
import { EditEmployeeFormComponent } from './forms/edit-employee-form/edit-employee-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhonePipe } from 'src/app/_helpers/phone.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserUploadComponent } from './user-upload/user-upload.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
 


@NgModule({
  declarations: [
    EmployeeListComponent,
    InviteEmployeeFormComponent,
    EditEmployeeFormComponent,
    UserUploadComponent,
    PhonePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    
    MatTableModule,
    MatSortModule,
    MatCardModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSlideToggleModule
  ],
  exports: [
    EmployeeListComponent,
    InviteEmployeeFormComponent,
    EditEmployeeFormComponent,
    UserUploadComponent
  ]
})
export class EmployeeManagementModule { }
