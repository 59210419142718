import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginChallenge } from '../_models/login-challenge';
import { API_URL } from 'src/app/constants';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _currentUserSubject: BehaviorSubject<User> | null;
  public currentUser: Observable<User>;

  constructor(private _http: HttpClient,
    public jwtHelper: JwtHelperService) {

    this._currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this._currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this._currentUserSubject.value;
  }

  public isAuthenticated(): boolean {
    if (this._currentUserSubject.value != null) {
      return !this.jwtHelper.isTokenExpired(this._currentUserSubject.value.accessToken);
    }

    return false;
  }

  public login(challenge: LoginChallenge) {

    return this._http.post<any>(`${API_URL}/Authentication`, challenge)
      .pipe(map(user => {
        //login successful if there is a jwt token in response
        if (user && user.accessToken) {
          const tokenPayload = <any>decode(user.accessToken);
          user.role = tokenPayload.role;

          //store user details and token in local storage to keep user logged in between refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this._currentUserSubject.next(user);
        }

        return user;
      }));
  }

  //remove user from local storage
  public logout() {
    sessionStorage.removeItem('currentUser');
    this._currentUserSubject.next(null);
  }

}
