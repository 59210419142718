<div class="container">
  <div class="row">
    <div class="col-lg-6 mx-auto d-flex justify-content-between">
      
      <div class="font-weight-bold h4 pb-3">Manage Departments 
     
       
    
    </div>

    <div class="">
      <a [matTooltip]="aboutThisPageToolTip" matTooltipClass="custom-tooltip" class="help-text">
      <img style="width:25px;" src="../../../../../assets/img/icons/info-circle-fill.svg"> <span class="tooltip-text" style="font-size:12px;"> About this page</span></a>
    </div>
  
  </div>  

 </div> 
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="teamForm">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label for="name">Department Name</label>
                <a [matTooltip]="addTeamToolTip" matTooltipClass="custom-tooltip">
                  <img   src="../../../../../assets/img/icons/info-circle-fill.svg">
                  
                </a>
            
             
              </div>     
              <input type="text" class="form-control valid" formControlName="name" id="name"
                      placeholder="Department Name" aria-labelledby="name" [ngClass]="{'is-invalid': submitted && f.name.errors}">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">Department name is required</div>
                </div>
            </div>
            <div class="d-flex justify-content-between">          
              <button type="submit" class="btn btn-primary custom-primary-btn" (click)="onSubmit()">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>