import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_shared/_services/authentication.service';
import { API_URL } from '../constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    
  constructor(private _authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with jwt token if available
      const currentUser = this._authService.currentUserValue;
      const isLoggedIn = currentUser && currentUser.accessToken;
      const isApiUrl = request.url.startsWith(API_URL);

      if (isLoggedIn && isApiUrl) {
        request = request.clone({
            setHeaders: { 
                Authorization: `Bearer ${currentUser.accessToken}`,
                "TenantId": currentUser.tenantId
            }
        });
      }

      return next.handle(request);
  }
}