import { NgModule } from '@angular/core';
 import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { TeamComponent } from  './pages/admin/team/team.component';
import { AtWorkComponent } from './pages/admin/at-work/at-work.component';
import { EmployeeManagementComponent } from './pages/admin/employee-management/employee-management.component';
import { EmployeeStatusDetailsComponent } from './pages/admin/employee-status-details/employee-status-details.component';
import { LoginComponent } from './pages/auth/login/login.component' 
import { InviteUserComponent } from './pages/admin/invite-user/invite-user.component';
import { EditEmployeeComponent } from './pages/admin/edit-employee/edit-employee.component';
import { AddTeamComponent } from './pages/admin/add-team/add-team.component';
import { AuthGuard } from './_guards/auth.guard';
import { RoleGuard } from './_guards/role.guard';
import { PasswordResetComponent } from './pages/auth/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { SymptomReportDetailsComponent } from './pages/admin/symptom-report-details/symptom-report-details.component';
import { ResetSuccessComponent } from './pages/auth/reset-success/reset-success.component';
import { AddSymptomReportComponent } from './pages/admin/add-symptom-report/add-symptom-report.component';
import { AddEmployeeSymptomReportComponent } from './_shared/_components/symptom-reports/forms/add-employee-symptom-report/add-employee-symptom-report.component';
import { ReportsComponent } from './pages/admin/reports/reports.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'at-work',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: PasswordResetComponent
  },
  {
    path: 'reset-success',
    component: ResetSuccessComponent
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin', 'User']
    },
    children: [
      {
        path: 'at-work',
        canActivate: [AuthGuard, RoleGuard],
        component: AtWorkComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard, RoleGuard],
        component: DashboardComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'employee-management',
        canActivate: [AuthGuard, RoleGuard],
        component: EmployeeManagementComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'reports',
        canActivate: [AuthGuard, RoleGuard],
        component: ReportsComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'at-work/teams',
        canActivate: [AuthGuard, RoleGuard],
        component: TeamComponent ,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'at-work/employee-status-details',
        canActivate: [AuthGuard, RoleGuard],
        component: EmployeeStatusDetailsComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'at-work/symptom-report-details',
        canActivate: [AuthGuard, RoleGuard],
        component: SymptomReportDetailsComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'employee-management/invite-employee',
        canActivate: [AuthGuard, RoleGuard],
        component: InviteUserComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'employee-management/edit-employee',
        canActivate: [AuthGuard, RoleGuard],
        component: EditEmployeeComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'employee-management/add-team',
        canActivate: [AuthGuard, RoleGuard],
        component: AddTeamComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      },
      {
        path: 'symptom-report/new',
        canActivate: [AuthGuard, RoleGuard],
        component: AddSymptomReportComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin', 'User']
        }
      },
      {
        path: 'symptom-report/employee-new',
        canActivate: [AuthGuard, RoleGuard],
        component: AddEmployeeSymptomReportComponent,
        data: {
          expectedRoles: ['SystemAdmin', 'CompanyManager', 'CompanyAdmin']
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
