import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Department } from 'src/app/_shared/_models/department';
import { Status } from 'src/app/_shared/_models/status';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
import { Role } from 'src/app/_shared/_models/role';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';
import { AccountService } from 'src/app/_shared/_services/account.service';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {

  public isLoading: boolean = false;
  public statusList: Status[];
  public departmentList: Department[];
  public currentProfile: UserProfile;
  public userProfile: UserProfile;
  public roles: Role[];

  constructor(private _accountService: AccountService,
              private _atWorkService: AtWorkService,
              private _router: Router) {
    if (this._router.getCurrentNavigation() == null) {
      this._router.navigate(['/employee-management']);
    }
    else {
      this.userProfile = <UserProfile>this._router.getCurrentNavigation().extras.state;
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._getData();
  }

  private _getData(): void {
    forkJoin([
      this._atWorkService.getDepartmentList(),
      this._atWorkService.getStatusList(),
      this._accountService.getRoles(),
      this._accountService.getProfile()
    ]).subscribe(([departments, statuses, roles, currentProfile]) => {
      this.statusList = statuses //.filter(status => status.statusId != 10);
      this.departmentList = departments;
      this.roles = roles;
      this.currentProfile = currentProfile;

      this.isLoading = false;
    }, error => console.error(error));
  }

}
