import { HttpClient } from '@angular/common/http';
import { ArrayType } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from 'src/app/constants';
import { Department } from '../_models/department';
import { DepartmentStatus } from '../_models/department-status';
import { ManagerProfile } from '../_models/manager-profile';
import { Status } from '../_models/status';
import { StatusReport } from '../_models/status-report';
import { Symptom } from '../_models/status-report-detail';
import { SymptomReport } from '../_models/symptom';
import { SymptomSummary } from '../_models/symptom-summary';
import { UserProfile } from '../_models/user-profile';

@Injectable({
  providedIn: 'root'
})
export class AtWorkService {

  constructor(private _http: HttpClient) { }

  public getUserProfiles(): Observable<UserProfile[]> {
    return this._http.get<UserProfile[]>(`${API_URL}/Account/TenantProfiles`);
  }

  public updateUserProfile(userProfile: UserProfile): Observable<UserProfile> {
    return this._http.put<UserProfile>(`${API_URL}/Account/UserProfile`, userProfile);
  }
  
  public getDepartmentList(): Observable<Department[]> {
    return this._http.get<Department[]>(`${API_URL}/Department`);
  }

  public getDepartmentStatuses(): Observable<DepartmentStatus[]> {
    return this._http.get<DepartmentStatus[]>(`${API_URL}/Department/Status`);
  }

  public addDepartment(department: Department): Observable<Department> {
    return this._http.post<Department>(`${API_URL}/Department`, department);
  }

  public editDepartment(department: Department): Observable<Department> {
    return this._http.put<Department>(`${API_URL}/Department`, department);
  }

  public deleteDepartment(department: Department): Observable<Department> {
    return this._http.delete<Department>(`${API_URL}/Department/${department.departmentId}`);
  }

  public getStatusList(): Observable<Status[]> {
    return this._http.get<Status[]>(`${API_URL}/Status/List`);
  }

  public getStatusReports(): Observable<StatusReport[]> {
    return this._http.get<StatusReport[]>(`${API_URL}/Status/StatusReports`);
  }

  public getSymptomSummary(): Observable<SymptomSummary> {
    return this._http.get<SymptomSummary>(`${API_URL}/Symptoms/SymptomSummary`);
  }

  public getManagerProfiles(): Observable<ManagerProfile[]> {
    return this._http.get<ManagerProfile[]>(`${API_URL}/Account/ManagerProfiles`);
  }

  public setDepartmentManagers(departmentID: number, managers: ManagerProfile[]): Observable<ManagerProfile[]> {
    return this._http.post<ManagerProfile[]>(`${API_URL}/Department/Managers/${departmentID}`, managers);
  }

  public getSymptomList(): Observable<Symptom[]> {
    return this._http.get<Symptom[]>(`${API_URL}/Symptoms`);
  }

  public addSymptomReport(report: SymptomReport): Observable<SymptomReport> {
    return this._http.post<SymptomReport>(`${API_URL}/Symptoms/Reports`, report);
  }

  public addEmployeeSymptomReport(email: string, report: SymptomReport): Observable<SymptomReport> {
    return this._http.post<SymptomReport>(`${API_URL}/Symptoms/EmployeeReports/${email}`, report);
  }

}
