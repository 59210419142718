import { Component, OnInit } from '@angular/core';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';
import * as moment from 'moment';
import { DepartmentStatus } from 'src/app/_shared/_models/department-status';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  public isLoading: boolean = false;
  public departmentList: DepartmentStatus[];

  constructor(private _atWorkService: AtWorkService) { }

  ngOnInit(): void {
    this.isLoading = true;

    this._atWorkService.getDepartmentStatuses().subscribe(res => {
      this.departmentList = res;

      this.departmentList.forEach(department => {
        department.statusReports.forEach(report => {
          report._lastReportString = report.lastReport ? moment(report.lastReport).format("YYYY/MM/DD") : 'N/A';
          report._firstReportString = report.firstReport ? moment(report.firstReport).format("YYYY/MM/DD") : 'N/A';

          report.statusDetails.forEach(detail => {
            detail._statusDateString = detail.statusDate ? moment(detail.statusDate).format("YYYY/MM/DD") : 'N/A';
          });
        });
      });

      this.isLoading = false;
    });
  }

}
