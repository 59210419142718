import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotPassword } from 'src/app/_shared/_models/forgot-password';
import { AccountService } from 'src/app/_shared/_services/account.service';
import { AlertService } from 'src/app/_shared/_services/alert.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public submitted: boolean = false;
  
  public resetForm = new FormGroup({
    email: new FormControl('',[Validators.required, Validators.email])
  });

  constructor(private _accountService: AccountService,
              private _alertService: AlertService,
              private _router: Router) { }

  ngOnInit(): void {
  }
  
  public get f() {
    return this.resetForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if(this.f.email.errors){
      return;
    }

    let value = new ForgotPassword();
    value.email = this.f.email.value;

    this._accountService.forgotPassword(value).subscribe(res => {
      this._alertService.success("Please check your email for the reset link", true);
      this._router.navigate(['/login']);
    },
    error => {
      console.error(error);
      this._alertService.error("Could not reset password", true);
      //this._router.navigate(['/login']);
    });
  }
}
