import { Component, Input, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-progress-pie-chart-two',
  templateUrl: './progress-pie-chart-two.component.html',
  styleUrls: ['./progress-pie-chart-two.component.css']
})
export class ProgressPieChartTwoComponent implements OnInit {

  @Input('reportsLastWeek') reportsLastWeek: number;
  
  constructor() { }

  ngOnInit(): void {

  }

}
