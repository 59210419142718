<mat-card>
  <mat-card-title>Symptom Report Audit</mat-card-title>
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="dateForm" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker>
        <mat-date-range-picker-actions>
          <button class="btn btn-secondary" matDateRangePickerCancel>Cancel</button>
          <button class="btn btn-primary" matDateRangePickerApply>Apply</button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>

      <mat-error *ngIf="dateForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="dateForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <button class="btn btn-primary" (click)="downloadAudit()">Download</button>
  </mat-card-content>
</mat-card>
