import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusReport } from 'src/app/_shared/_models/status-report';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { SymptomSummary } from 'src/app/_shared/_models/symptom-summary';
import { AccountService } from 'src/app/_shared/_services/account.service';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
 

@Component({
  selector: 'app-at-work',
  templateUrl: './at-work.component.html',
  styleUrls: ['./at-work.component.css']
})
export class AtWorkComponent implements OnInit {

  public isLoading: boolean = false;
  public symptomSummary: SymptomSummary;
  public userProfile: UserProfile;
 
  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _atWorkService: AtWorkService,
              private _accountService: AccountService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this._getData();
  }

  private _getData() {
    forkJoin([
      this._atWorkService.getSymptomSummary(),
      this._accountService.getProfile()
    ]).subscribe(([symptomSummary, userProfile]) => {
      this.symptomSummary = symptomSummary;
      this.userProfile = userProfile;

      this.isLoading = false;
    }, error => console.error(error));
  }

}
