import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { DashboardModule } from './_shared/_components/dashboard/dashboard.module';
import { BaseLayoutModule } from './layouts/base-layout/base-layout.module';
import { AtWorkModule } from './_shared/_components/at-work/at-work.module';
import { ProgressModule } from './_shared/_components/charts/progress.module';
import { TeamModule } from './_shared/_components/teams/team.module';
import { DetailsModule } from './_shared/_components/details/details.module';
import { EmployeeManagementModule } from './_shared/_components/employee-management/employee-management.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from './_shared/_services/authentication.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from './_shared/_services/alert.service';
import { AtWorkService } from './_shared/_services/at-work.service';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { SafeLinkPipe } from './_helpers/safe-link.pipe';
 
import { PasswordResetComponent } from './pages/auth/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { AlertComponent } from './_shared/_components/alert/alert.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from './_shared/_components/alert/alert.module';
import { ResetSuccessComponent } from './pages/auth/reset-success/reset-success.component';
import { SymptomReportsModule } from './_shared/_components/symptom-reports/symptom-reports.module';
import { ReportsComponent } from './pages/admin/reports/reports.component';
import { ReportsModule } from './_shared/_components/reports/reports.module';
// import { AddSymptomReportFormComponent } from './_shared/_components/symptom-reports/forms/add-symptom-report-form/add-symptom-report-form.component';
// import { AddSymptomReportComponent } from './pages/admin/add-symptom-report/add-symptom-report.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SafeLinkPipe,
    PasswordResetComponent,
    ForgotPasswordComponent,
    ResetSuccessComponent,
    ReportsComponent 
     
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    JwtModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppRoutingModule,
    AlertModule,
    BaseLayoutModule,
    DashboardModule,
    AtWorkModule,
    ProgressModule,
    TeamModule,
    DetailsModule,
    EmployeeManagementModule,
    SymptomReportsModule,
    ReportsModule,
    NgbModule,
    NgxPaginationModule,
    BrowserAnimationsModule
    
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    DatePipe,
    AuthenticationService,
    JwtHelperService,
    AlertService,
    AtWorkService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
