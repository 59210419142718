<div class="container mb-5">
  <div class="row">
    <div class="col-lg-10">
      <div class="sub-title-text">Require Check In<span class="pl-4"><button type="button" class="btn btn-warning flag"
            disabled="">Pending</button></span></div>
      <p>Employees who are eligible to work but have not completed a survey today. They will be cleared upon completion of their Daily Symptom Report.</p>
    </div>

  </div>
  <div class="row">
    
    <div class="col-lg-10">
      
      <mat-card class="table-card">
        <div class="d-flex justify-content-end pr-1">
            <a [matTooltip]="requireCheckinToolTip" matTooltipClass="custom-tooltip">
             <img src="../../../../../assets/img/icons/info-circle-fill.svg"> 
          </a>
        </div>
        <table mat-table #sortRequired="matSort" matSort [dataSource]="dataSourceRequired" class="table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let report">{{report.name}}</td>
          </ng-container>
          <ng-container matColumnDef="_lastReportString">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Report</th>
            <td mat-cell *matCellDef="let report">{{report._lastReportString}}</td>
          </ng-container>
          <ng-container matColumnDef="statusTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Status</th>
            <td mat-cell *matCellDef="let report">{{report.statusTitle}}</td>
          </ng-container>

          <ng-container matColumnDef="completeReport">
            <th mat-header-cell *matHeaderCellDef>Report</th>
            <td mat-cell *matCellDef="let report">
              <button *ngIf="report.statusId == 5" type="button" class="btn btn-warning" (click)="goToSurvey(report)">Complete</button>
            </td>
          </ng-container>
 
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let report" class="link" (click)="goToDetails(report)">
              <img src="assets/img/icons/details_1.svg">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsRequired"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsRequired;"></tr>
        </table>
        <mat-paginator #paginatorRequired [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
</div>


<!-- <mat-card class="table-card"> 
<div id="no-more-tables">
<table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Last Report</th>
        <th>Current Status</th>
        <th>Report</th>
        <th>Details</th>
   
      </tr>
    </thead>
    <tbody>
      <tr class="mobile-row">
       <a class="accordion-collapse-header" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"> <td data-title="Matt Barbuzzi" class="numeric">Report Required</td></a> 
        <div class="collapse" id="collapseExample">
         <td data-title="Last Report" class="numeric">2020/04/05</td>
        <td data-title="Current Status" class="numeric">N/A (Symptom Report Required)</td>
        <td data-title="Report" class="numeric"> <button type="button" class="btn btn-warning" >Complete</button></td>
        <td data-title="Details" class="numeric"><button type="button" class="btn btn-primary" >View</button></td>
      </div>
      </tr>
    </tbody>
  </table>
</div>


</mat-card> -->




<div class="container mb-5">
  <div class="row">
    <div class="col-lg-10">
      <div class="sub-title-text">Cleared <span class="pl-3"><button type="button" class="btn btn-success flag"
            disabled="">Cleared</button></span></div>
      <p>Employees who have tested negative, completed their mandatory quarantine, and have completed a survey today.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-10">
      <mat-card class="table-card">
        <div class="d-flex justify-content-end pr-1">
          <a [matTooltip]="clearedCheckinToolTip" matTooltipClass="custom-tooltip">
           <img src="../../../../../assets/img/icons/info-circle-fill.svg"> 
        </a>
      </div>
        <table mat-table #sortCleared="matSort" matSort [dataSource]="dataSourceCleared" class="table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let report">{{report.name}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="_firstReportString">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Report</th>
            <td mat-cell *matCellDef="let report">{{report._firstReportString}}</td>
          </ng-container> -->
          <ng-container matColumnDef="_lastReportString">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Report</th>
            <td mat-cell *matCellDef="let report">{{report._lastReportString}}</td>
          </ng-container>
          <ng-container matColumnDef="statusTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Status</th>
            <td mat-cell *matCellDef="let report">{{report.statusTitle}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="shift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
            <td mat-cell *matCellDef="let report">Day Shift</td>
          </ng-container> -->
          <ng-container matColumnDef="temperatureCheckStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Temperature Status</th>
            <td mat-cell *matCellDef="let report">{{report._tempCheckString}}</td>
          </ng-container>
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let report" class="link" (click)="goToDetails(report)">
              <img src="assets/img/icons/details_1.svg">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsCleared"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsCleared;"></tr>
        </table>
        <mat-paginator #paginatorCleared [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
</div>



<div class="container mb-5">
  <div class="row">
    <div class="col-lg-10">
      <div class="sub-title-text">Pending Cases <span class="pl-4"><button type="button" class="btn btn-warning flag"
            disabled="">Pending</button></span></div>
      <p>Employees who have been in close contact with a positive case, have shown reported symptoms indicating possible
        infection, or are awaiting test results.</p>
    </div>

  </div>
  <div class="row">
    <div class="col-lg-10">
      <mat-card class="table-card">
        <div class="d-flex justify-content-end pr-1">
          <a [matTooltip]="pendingCasesToolTip" matTooltipClass="custom-tooltip">
           <img src="../../../../../assets/img/icons/info-circle-fill.svg"> 
        </a>
      </div>
        <table mat-table #sortPending="matSort" matSort [dataSource]="dataSourcePending" class="table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let report">{{report.name}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="_firstReportString">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Report</th>
            <td mat-cell *matCellDef="let report">{{report._firstReportString}}</td>
          </ng-container> -->
          <ng-container matColumnDef="_lastReportString">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Report</th>
            <td mat-cell *matCellDef="let report">{{report._lastReportString}}</td>
          </ng-container>
          <ng-container matColumnDef="statusTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Status</th>
            <td mat-cell *matCellDef="let report">{{report.statusTitle}}</td>
          </ng-container>
          <ng-container matColumnDef="temperatureCheckStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Temperature Status</th>
            <td mat-cell *matCellDef="let report">{{report._tempCheckString}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="shift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
            <td mat-cell *matCellDef="let report">Day Shift</td>
          </ng-container> -->
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let report" class="link" (click)="goToDetails(report)">
              <img src="assets/img/icons/details_1.svg">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsPending"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsPending;"></tr>
        </table>
        <mat-paginator #paginatorPending [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
</div>

<div class="container mb-5">
  <div class="row">
    <div class="col-lg-10">
      <div class="sub-title-text">Confirmed Cases <span class="pl-4"><button type="button" class="btn btn-danger flag"
            disabled="">Confirmed</button></span></div>
      <p>Employees who have tested positive and are currently in quarantine.</p>
    </div>

  </div>
  <div class="row">
    <div class="col-lg-10">
      <mat-card class="table-card">
        <div class="d-flex justify-content-end pr-1">
          <a [matTooltip]="confirmedCasesToolTip" matTooltipClass="custom-tooltip">
           <img src="../../../../../assets/img/icons/info-circle-fill.svg"> 
        </a>
      </div>
        <table mat-table #sortFlagged="matSort" matSort [dataSource]="dataSourceFlagged" class="table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let report">{{report.name}}</td>
          </ng-container>
          <ng-container matColumnDef="_quarantineStartString">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quarantine Start</th>
            <td mat-cell *matCellDef="let report">{{report._quarantineStartString}}</td>
          </ng-container>
          <ng-container matColumnDef="_quarantineEndString">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quarantine End</th>
            <td mat-cell *matCellDef="let report">{{report._quarantineEndString}}</td>
          </ng-container>
          <ng-container matColumnDef="daysRemaining">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Days Remaining</th>
            <td mat-cell *matCellDef="let report">{{report.daysRemaining}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="shift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
            <td mat-cell *matCellDef="let report">Day Shift</td>
          </ng-container> -->
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let report" class="link" (click)="goToDetails(report)">
              <img src="assets/img/icons/details_1.svg">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsFlagged"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsFlagged;"></tr>
        </table>
        <mat-paginator #paginatorFlagged [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>

</div>
