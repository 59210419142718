import { Component, OnInit } from '@angular/core';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
import { AccountService } from 'src/app/_shared/_services/account.service';
 

@Component({
  selector: 'app-employee-management',
  templateUrl: './employee-management.component.html',
  styleUrls: ['./employee-management.component.css']
})
export class EmployeeManagementComponent implements OnInit {

  public userProfile: UserProfile;

  constructor(private _accountService: AccountService) { }

  ngOnInit(): void {
    this._accountService.getProfile().subscribe(res => {
      this.userProfile = res;
    });
  }
    

}
