import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_shared/_services/alert.service';
import { AuthenticationService } from 'src/app/_shared/_services/authentication.service';

declare var $: any;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit, AfterViewInit {

  currentUserRole: string;

  constructor(private _authService: AuthenticationService,
              private _alertService: AlertService,
              private _router: Router) { }

  ngOnInit(): void {
    this.currentUserRole = this._authService.currentUserValue.role;
  }

  ngAfterViewInit(): void {
    document.querySelectorAll('nav ul li ul li a').forEach(item => {
      item.addEventListener('click', event => {
        var el = $("nav ul li ul li a").removeClass("activeSubLink");

        var element = (<any>event).path[0];
        element.classList.add("activeSubLink");
      })
    });

    window.addEventListener('load', (event) => {
      document.getElementById("sidebarCollapse").onclick = function toggleClass() {
          var menu = document.getElementById("sidebar");
          menu.classList.toggle('active');
      };
      document.getElementById("sidebarClose").onclick = function closeSidebar() {
          var menu = document.getElementById("sidebar");
          menu.classList.toggle("active");
      };
    });
  }

  logout(){
    this._authService.logout();
    this._alertService.clear();
    this._router.navigate(['/login']);
  }

}
