import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StatusReport } from 'src/app/_shared/_models/status-report';
import { UserProfile } from 'src/app/_shared/_models/user-profile';
import { AtWorkService } from 'src/app/_shared/_services/at-work.service';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
 

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  @Input('profile') userProfile: UserProfile;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public isLoading: boolean = false;
  public userProfiles: UserProfile[];
  public selectedUser: UserProfile;
  
  public displayedColumns: string[] = ['name', 'statusLevel', 'email', 'phoneNumber', 'team', 'details', 'edit'];
  public dataSource: MatTableDataSource<UserProfile> = new MatTableDataSource<UserProfile>();
  public aboutThisPageToolTip: string;
  public allEmployeesToolTip: string;

  constructor(private _atWorkService: AtWorkService,
              private _router: Router) {

                this.aboutThisPageToolTip = "What does this page show?\n\nThe Manage Employees page displays all of the employees at your company who are currently registered to @Work. Use this page to quickly view all employees and their individual statuses, as well as what team or department they belong to.";
                this.allEmployeesToolTip = "All employees are shown on this card with their ‘Case Status’ clearly displayed. You can view each individual employee’s historical symptom log by clicking on the notepad icon under the ‘Details’ column to understand the reason for their status. The filter feature allows you to search for an employee by first or last name by typing it in the search bar in the top right corner of the card. \n\nInvite an Employee\n\nUse this page to invite a new employee to start using @Work by clicking on the “Invite Employee” button in the top right corner. Enter the employee’s information into the form that pops up and click ‘send invite’. They will receive an email inviting them to join @Work with instructions on how to get started.";

  }

  ngOnInit(): void {
    this.isLoading = true;
    this._atWorkService.getUserProfiles().subscribe(res => {
      this.userProfiles = res;

      this.userProfiles.forEach(profile => {
        profile.name = profile.firstName + " " + profile.lastName;
        profile.team = profile.department.departmentName;
        profile.statusLevel = profile.status.statusLevel;

        profile.statusReport._lastReportString = profile.statusReport.lastReport ? moment(profile.statusReport.lastReport).format("YYYY/MM/DD") : 'N/A';
        profile.statusReport._firstReportString = profile.statusReport.firstReport ? moment(profile.statusReport.firstReport).format("YYYY/MM/DD") : 'N/A';
        profile.statusReport._quarantineStartString = profile.statusReport.quarantineStart ? moment(profile.statusReport.quarantineStart).format("YYYY/MM/DD") : 'N/A';
        profile.statusReport._quarantineEndString = profile.statusReport.quarantineEnd ? moment(profile.statusReport.quarantineEnd).format("YYYY/MM/DD") : 'N/A';

        profile.statusReport.statusDetails.forEach(detail => {
          detail._statusDateString = moment(detail.statusDate).format("YYYY/MM/DD");
        });
      });

      // hide qr code if not cardiotrack
      if (this.userProfile.requireTemperatureCheck && this.userProfile.temperatureCheckProvider === "1") {
        this.displayedColumns = ['name', 'statusLevel', 'email', 'phoneNumber', 'team', 'details', 'edit', 'qrCode'];
      }

      this.dataSource = new MatTableDataSource(this.userProfiles);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      
      
      this.isLoading = false;
    }, error => console.error(error));
  }

  public applyFilter(filterEvent: any) {
    let filterValue = filterEvent.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public selectUser(user: UserProfile) {
    this.selectedUser = user;
    this.selectedUser.qrImageUrl = 'data:image/png;base64,' + user.cardioTrackUser.cardioTrackQrCode;
    this.selectedUser.qrImageName = 'qr' + user.firstName + user.lastName + '.png';
  }

  public goToEditUser(user: UserProfile): void {
    this._router.navigate(['/employee-management/edit-employee'], {state: user});
  }

  public goToDetails(report: StatusReport): void {
    this._router.navigate(['/at-work/employee-status-details'], {state: report});
  }

}
