<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <div class="collapse navbar-collapse d-flex justify-content-end " id="navbarSupportedContent">
             <div class="user-info">
                <div class="full-name">  
                Matt Barbuzzi</div>
                <div class="company">achu health</div></div>
             <div class="profile pl-3"><img src="assets/img/icons/person-circle.svg" width="32" height="32"></div>
        </div>
    </div>
</nav> -->

<nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between">
  <div class="d-flex">
    <button class="navbar-toggler" type="button" id="sidebarCollapse">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!isLoading">


    <div class="d-flex flex-column">
      <div class="full-name">
        {{name}}
      </div>
      <div class="company">{{tenantName}}</div>
    </div>
    <!-- <div class="d-flex pl-3">
        <a id="profile" data-toggle="popover" data-placement="bottom" data-content="">
        <img src="assets/img/icons/person-circle.svg" width="32" height="32"></a>
    </div> -->
    <!-- <ng-template #popTitle>{{name}}</ng-template>
    <ng-template #popContent><a href="" class="btn btn-primary custom-primary-btn">Log Out</a></ng-template>
    <div class="d-flex pl-3">
        <button type="button" [ngbPopover]="popContent" [popoverTitle]="popTitle">
        <img src="assets/img/icons/person-circle.svg" width="32" height="32"></button>
    </div> -->
    <app-profile-tooltip [userName]="name"></app-profile-tooltip>
  </div>
</nav>
