import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './base-layout.component'
import { PartialsModule } from 'src/app/_shared/_components/layouts/partials/partials.module';
import { DashboardModule } from '../../_shared/_components/dashboard/dashboard.module'
import { DashboardComponent } from 'src/app/pages/admin/dashboard/dashboard.component';
import { AtWorkModule } from 'src/app/_shared/_components/at-work/at-work.module';
import { AtWorkComponent } from 'src/app/pages/admin/at-work/at-work.component';
import { TeamComponent } from '../../pages/admin/team/team.component';
import { TeamModule } from 'src/app/_shared/_components/teams/team.module';
import { DetailsModule } from 'src/app/_shared/_components/details/details.module';
import { EmployeeStatusDetailsComponent } from 'src/app/pages/admin/employee-status-details/employee-status-details.component';
import { SymptomReportDetailsComponent } from 'src/app/pages/admin/symptom-report-details/symptom-report-details.component';
import { EmployeeManagementComponent } from '../../pages/admin/employee-management/employee-management.component';
import { EmployeeManagementModule } from 'src/app/_shared/_components/employee-management/employee-management.module';
import { InviteUserComponent } from 'src/app/pages/admin/invite-user/invite-user.component';
import { EditEmployeeComponent } from 'src/app/pages/admin/edit-employee/edit-employee.component';
import { AddTeamComponent } from 'src/app/pages/admin/add-team/add-team.component';
import { AlertModule } from 'src/app/_shared/_components/alert/alert.module';
import { AddSymptomReportComponent } from 'src/app/pages/admin/add-symptom-report/add-symptom-report.component';
import { SymptomReportsModule } from 'src/app/_shared/_components/symptom-reports/symptom-reports.module';
    
@NgModule({
  declarations: [
    BaseLayoutComponent,
    EmployeeManagementComponent,
    TeamComponent,
    DashboardComponent,
    AtWorkComponent,
    EmployeeStatusDetailsComponent,
    SymptomReportDetailsComponent,
    InviteUserComponent,
    EditEmployeeComponent,
    AddTeamComponent,
    AddSymptomReportComponent 
  ],
  imports: [
    CommonModule,
    RouterModule,
    AlertModule,
    PartialsModule,
    DashboardModule,
    AtWorkModule,
    TeamModule,
    DetailsModule,
    EmployeeManagementModule ,
    SymptomReportsModule
  ]
})
export class BaseLayoutModule { }
