<div class="container-fluid p-0 bg-light h-100 ">
  <div class="row h-100 align-items-center justify-content-center">
    <div class="col-xl-3 col-lg-3 col-md-4 col-10 text-center p-3 login-wrapper">
      <div class="text-center">
        <img src="https://achuhealth.com/images/achu@work.png" width="150" class="mt-5" alt="">
        <p>By Achu Health™</p>
      </div>

      <app-alert></app-alert>

      <form class="p-4 text-center" [formGroup]="passwordForm">
        <p>Reset Password</p>

        <div class="form-group">
          <label class="d-flex">New Password</label>
          <input formControlName="newPassword" type="password" class="form-control"
            [class.is-invalid]="f.newPassword.invalid && f.newPassword.touched">
          <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
            <div *ngIf="f.newPassword.errors.required" class="text-danger">Password is required</div>
          </div>
        </div>

        <div class="form-group">
          <label class="d-flex">Confirm New Password</label>
          <input formControlName="confirmNewPassword" type="password" class="form-control"
            [class.is-invalid]="f.confirmNewPassword.invalid && f.confirmNewPassword.touched">
          <div *ngIf="submitted && f.confirmNewPassword.errors" class="invalid-feedback">
            <div *ngIf="f.confirmNewPassword.errors.required" class="text-danger">Must confirm password</div>
            <div *ngIf="f.confirmNewPassword.errors.mustMatch" class="text-danger">Passwords must match</div>
          </div>
        </div>

        <div class="col-lg-12 mx-auto">
          <div class="form-group">
            <div class="checkbox checkbox-justified">
              <label>
                <input name="minCharacters" type="checkbox" disabled
                  [checked]="f.newPassword.errors?.length || !f.newPassword.errors"><span class="light-text">6 or more
                  characters</span>
              </label>
            </div>
            <div class="checkbox checkbox-justified">
              <label>
                <input name="upperAndLowercase" type="checkbox" disabled=""
                  [checked]="f.newPassword.errors?.lowerAndUpper || !f.newPassword.errors"><span
                  class="light-text">Upper &amp; lowercase letters</span>
              </label>
            </div>
            <div class="checkbox checkbox-justified">
              <label>
                <input name="oneNumber" type="checkbox" disabled=""
                  [checked]="f.newPassword.errors?.number || !f.newPassword.errors"><span class="light-text">At least
                  one number</span>
              </label>
            </div>
          </div>
        </div>


        <button (click)="onSubmit()" type="submit" class="btn btn-primary custom-primary-btn px-5 mt-3">Submit</button>
      </form>
    </div>
  </div>
</div>
