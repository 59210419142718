import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_shared/_services/authentication.service';

declare var $:any;


@Component({
  selector: 'app-profile-tooltip',
  templateUrl: './profile-tooltip.component.html',
  styleUrls: ['./profile-tooltip.component.css']
})
export class ProfileTooltipComponent implements OnInit {

  @Input('userName') userName: string;

  constructor() { 
    // const comp: string = this.userName;
    // $(function(){
    
    //   $("[data-toggle=popover]").popover({
    //     sanitize: false, // <-- ADD HERE
    //     html: true,
    //     content: function() {
    //         var content = '<button #logoutButton class="btn btn-primary custom-primary-btn">Sign Out</button>';
    //         return content;
    //     },
    //     title: function() {
    //       var title = comp;
    //       return title;
    //     }
    //   });
    // });
  }

  ngOnInit(): void {
    
  }

}
