<div class="container mb-5">
  
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Department Name</th>
            <th scope="col">Edit Name</th>
            <th scope="col">Edit Managers</th>
            <th scope="col">Delete</th>
            <!-- <a [matTooltip]="departmentListToolTip" matTooltipClass="custom-tooltip">
              <img   src="../../../../../assets/img/icons/info-circle-fill.svg">
              
            </a> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let department of departments">
            <td>{{department.departmentName}}</td>
            <td>
              <img src="assets/img/icons/edit.png" class="link" (click)="selectDepartment(department)"
                    data-toggle="modal" data-target="#editModal">
            </td>
            <td>
              <button type="button" class="btn btn-primary manager-btn" (click)="selectDepartment(department)"
                      data-toggle="modal" data-target="#managerModal">Managers</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger" (click)="selectDepartment(department)"
                      data-toggle="modal" data-target="#deleteModal">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="editModalLabel">Edit Department</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editForm">
          <div class="form-group">
            <label for="depName">Department Name</label>     
            <input type="text" class="form-control valid" formControlName="depName" id="depName"
                    placeholder="Name" aria-labelledby="depName" [ngClass]="{'is-invalid': submitted && f.depName.errors}">
              <div *ngIf="submitted && f.depName.errors" class="invalid-feedback">
                <div *ngIf="f.depName.errors.required">Department name is required</div>
              </div>
          </div>
        </form>
        <div class="form-group">
          <label for="reminderTime">Notification Time</label>
          <ngb-timepicker id="reminderTime" [(ngModel)]="time" [meridian]="true"></ngb-timepicker>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary submit-btn" (click)="onSubmit()">Edit</button>
      </div>
    </div>
  </div>
</div>

<!-- Manager Modal -->
<div class="modal fade" id="managerModal" tabindex="-1" role="dialog" aria-labelledby="managerModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="managerModalLabel">Edit Managers</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section *ngFor="let manager of managers">
          <mat-checkbox [checked]="manager._checked" (change)="onCheckChange(manager)">{{manager.name}}</mat-checkbox>
        </section>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary manager-btn" (click)="onManagerSubmit()">Set Managers</button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="deleteModalLabel">Delete Department</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete {{selectedDepartment?.departmentName}}?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary delete-btn" (click)="onDelete()" data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>
